import React, { Component } from "react";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { AccountBalance, Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { login } from "../actions/postActions";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import institutions from "../configuration/institutions.csv";
import { Redirect } from "react-router-dom";

import { successfulAuth } from "../actions/postActions";
import { CSVReader, readString, readRemoteFile } from "react-papaparse";

import { fetchForms } from "../actions/postActions";

import configuration from "../form_schemas/configuration.json";
import configurationFR from "../form_schemas/configuration-fr.json";
import formSchemaImport from "../form_schemas/collections.json";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "90vh",
  },
  card: {
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  leftAlign: {
    textAlign: "left",
  },
}));

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      institution: "",
      email: "",
      password: "",
      showPassword: false,
      errorMessage: "",
      successMessage: "",
      institutions: [],
      sentReset: false,
      user: this.props.user,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    // this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.reset = this.reset.bind(this);
    this.loginReq = this.loginReq.bind(this);
  }

  componentWillReceiveProps(nextState) {
    // load in all calendar events on init
    // console.log('next state', nextState.admin)
    this.setState({
      user: nextState.user,
    });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  // const classes = useStyles();
  // const [values, setValues] = React.useState({
  //   institution: "",
  //   email: "",
  //   password: "",
  //   showPassword: false,
  //   errorMessage: "",
  //   insitutions: [],
  //   isAuthenticated: false,
  //   user: this.props.user,
  // });

  // useEffect(() => {
  //   // parse text file into array

  //   fetch(insitutions)
  //     .then((r) => r.text())
  //     .then((text) => {
  //       let temp = [];
  //       text.split("\n").forEach(function (item, index) {
  //         if (item) {
  //           temp.push(item);
  //         }
  //       });
  //       this.setState({ insitutions: temp });
  //     });
  // }, []);

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  validateInput = () => {
    if (!this.state.institution || this.state.institution.length === 0)
      if (this.props.language == "fr") {
        return configurationFR.Login["validate-institution"];
      } else {
        return configuration.Login["validate-institution"];
      }
    if (!this.state.email || this.state.email.length === 0)
      if (this.props.language == "fr") {
        return configurationFR.Login["validate-email"];
      } else {
        return configuration.Login["validate-email"];
      }
    if (!this.state.password || this.state.password.length === 0)
      if (this.props.language == "fr") {
        return configurationFR.Login["validate-password"];
      } else {
        return configuration.Login["validate-password"];
      }
    return "valid";
  };

  reset = () => {
    var promise = new Promise((resolve) => {
      var payload = {
        email: this.state.email,
      };
      console.log(payload);
      this.loginReq(resolve, payload);
    });
    return promise;
  };

  loginReq = (resolve, payload) => {
    axios
      .post(process.env.REACT_APP_API + "/users/reset", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.successful === false) {
          console.log("login failed");
          if (this.props.language == "fr") {
            this.setState({
              errorMessage: configurationFR.ForgotPassword["not-found"],
            });
          } else {
            this.setState({
              errorMessage: configuration.ForgotPassword["not-found"],
            });
          }

          // server's error message
          if (response.data.reason) {
            if (this.props.language == "fr") {
              resolve(configurationFR.ForgotPassword["not-found"]);
            } else {
              resolve(configuration.ForgotPassword["not-found"]);
            }
          }
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve("");
          if (this.props.language == "fr") {
            this.setState({
              successMessage: configurationFR.ForgotPassword["success"],
            });
          } else {
            this.setState({
              successMessage: configuration.ForgotPassword["success"],
            });
          }
          console.log(response);

          // this.setState({ isAuthenticated: true });
          // this.setState({ sentReset: true });
          // set user info to redux
          // console.log('login succeeded')
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        console.log(error);
        // Should send a response of 400 ***
        if (this.props.language == "fr") {
          this.setState({
            errorMessage: configurationFR.ForgotPassword["not-found"],
          });
        } else {
          this.setState({
            errorMessage: configuration.ForgotPassword["not-found"],
          });
        }
        resolve(errorMsg);
      });
  };

  componentWillMount = () => {
    // read in institutions

    // this.setState({ insitutions: readString(institutions) });
    // console.log(readString(institutions));

    // try {
    //   readRemoteFile("../configuration/institutions.csv", {
    //     download: true,
    //     complete: (results) => {
    //       console.log(results);
    //     },
    //   });
    // } catch (error) {
    //   console.log(error);
    // }

    // fetch(institutions).then(function (response) {
    //   let reader = response.body.getReader();
    //   let decoder = new TextDecoder("utf-8");

    //   let data = decoder.decode(reader.read().value);
    //   console.log(data);
    //   this.setState({ institutions: data });
    //   // reader.read().then(function (result) {
    //   //   let data = decoder.decode(result.value);
    //   //   console.log(data);
    //   //   this.setState({ institutions: "Invalid Login Credentials" });
    //   //   // this.setState({
    //   //   //   institutions: data,
    //   //   // });
    //   // });
    // });
    fetch(institutions)
      .then((r) => r.text())
      .then((text) => {
        let temp = [];
        text.split("\n").forEach(function (item, index) {
          if (item) {
            // only accept institution name, not group.
            temp.push(item.split(",")[0]);
          }
        });
        console.log(temp);
        this.setState({ institutions: temp });
      });
  };

  render() {
    const classes = {
      root: {},
      card: {},
      leftAlign: {
        textAlign: "left",
      },
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "90vh",
        }}
        className={classes.root}
      >
        {/* {this.state.sentReset && <Redirect to="/login" />} */}
        <Grid
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          <Grid item>
            <div />
          </Grid>
          <Card
            style={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              padding: 30,
              width: 400,
            }}
          >
            <Grid style={{}} container direction={"column"} spacing={3}>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="h4">
                  {this.props.language == "en" ? (
                    <>{configuration.ForgotPassword["page-title"]}</>
                  ) : (
                    <>{configurationFR.ForgotPassword["page-title"]}</>
                  )}
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="body2" style={{ color: "gray" }}>
                  {this.props.language == "en" ? (
                    <>{configuration.ForgotPassword["page-description"]}</>
                  ) : (
                    <>{configurationFR.ForgotPassword["page-description"]}</>
                  )}
                </Typography>
              </Grid>
              {this.state.errorMessage.length > 0 && (
                <Grid item style={{ textAlign: "left" }}>
                  <Typography variant="body2" style={{ color: "red" }}>
                    {this.state.errorMessage}{" "}
                  </Typography>
                </Grid>
              )}
              {this.state.successMessage.length > 0 && (
                <Grid item style={{ textAlign: "left" }}>
                  <Typography variant="body2" style={{ color: "green" }}>
                    {this.state.successMessage}{" "}
                  </Typography>
                </Grid>
              )}

              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>
                    {this.props.language == "en" ? (
                      <>{configuration.ForgotPassword["email-placeholder"]}</>
                    ) : (
                      <>{configurationFR.ForgotPassword["email-placeholder"]}</>
                    )}
                  </InputLabel>
                  <OutlinedInput
                    type="text"
                    labelWidth={70}
                    value={this.state.email}
                    onChange={this.handleChange("email")}
                  />
                </FormControl>
              </Grid>

              <Grid item container justify="center">
                <Button variant="outlined" color="primary" onClick={this.reset}>
                  {this.props.language == "en" ? (
                    <>{configuration.ForgotPassword["button-text"]}</>
                  ) : (
                    <>{configurationFR.ForgotPassword["button-text"]}</>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Link id="Login" to={"/login"} style={{ textDecoration: "none" }}>
            <Typography
              variant="body2"
              style={{ color: "gray", marginTop: 25 }}
            >
              {this.props.language == "en" ? (
                <>{configuration.ForgotPassword["back-to-login"]}</>
              ) : (
                <>{configurationFR.ForgotPassword["back-to-login"]}</>
              )}
            </Typography>
          </Link>
          <Grid item>
            <div />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  successfulAuth,
  fetchForms,
})(ForgotPassword);
