import {
  AUTH_SUCCESS,
  FORM_STATUS,
  LANGUAGE,
  YEAR,
  INSTITUTIONS,
} from "../constants/action-types";
import axios from "axios";

export const login = (json) => {
  return {
    type: "LOGIN",
    user: json,
  };
};

export const logout = (json) => {
  return {
    type: "LOGOUT",
    user: json,
  };
};

export function successfulAuth(email, adminId, name, institution) {
  return function (dispatch) {
    // console.log(email, adminId, name)
    dispatch(setAuth(email, adminId, name, institution));
  };
}

export function successfulAuthOut() {
  // console.log("log out redux")
  return function (dispatch) {
    dispatch(setAuthOut());
  };
}

export function setAuthOut() {
  // console.log("log out redux auth")
  return {
    type: AUTH_SUCCESS,
    payload: {
      username: "",
      id: "",
      name: "",
      institution: "",
      loggedIn: false,
    },
  };
}

export function setAuth(email, adminId, name, institution) {
  // console.log("log in redux auth")
  return {
    type: AUTH_SUCCESS,
    payload: {
      username: email,
      id: adminId,
      name: name,
      institution: institution,
      loggedIn: true,
    },
  };
}

export function setLang(lang) {
  return {
    type: LANGUAGE,
    payload: lang,
  };
}

export function setYear(year) {
  return {
    type: YEAR,
    payload: year,
  };
}

export function fetchForms(institution, id) {
  console.log("fetch form status", institution, id);
  return function (dispatch) {
    return axios
      .get(process.env.REACT_APP_API + "/form-status", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          institution: institution,
          id: id,
        },
      })
      .then(({ data }) => {
        console.log("forms returned", data, id);
        dispatch(setForms(data));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
}
export function setForms(status) {
  return {
    type: FORM_STATUS,
    payload: status,
  };
}

export function fetchInstitutions() {
  return function (dispatch) {
    return axios
      .get(process.env.REACT_APP_API + "/institutions", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        console.log("institutions", response);
        dispatch(
          setInstitutions(
            response.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
          )
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
}

export function setInstitutions(institutions) {
  return {
    type: INSTITUTIONS,
    payload: institutions,
  };
}
