import React, { useState, useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import axios from "axios";
import ABRCSnackbar from "../components/snackbar";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EditOutlined } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 280,
    marginTop: 64,
    padding: 40,
  },
  textSpacing: {
    marginTop: 10,
    marginBottom: 10,
  },
  dialog: {
    padding: 20,
  },
  maxWidth: {
    width: 550,
    marginBottom: 10,
  },
}));

// Table theme
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

function Institutions(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openAddInstitution, setOpenAddInstitution] = useState(false);
  const [createInstitutionSuccess, setCreateInstitutionSuccess] =
    useState(false);
  const [createInstitutionError, setCreateInstitutionError] = useState(false);
  const [openEditInstitution, setOpenEditInstitution] = useState(false);
  const [editInstitutionSuccess, setEditInstitutionSuccess] = useState(false);
  const [editInstitutionError, setEditInstitutionError] = useState(false);
  const [deleteInstitutionSuccess, setDeleteInstitutionSuccess] =
    useState(false);
  const [deleteInstitutionError, setDeleteInstitutionError] = useState(false);
  const [id, setID] = useState("");
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [province, setProvince] = useState("");
  const [groups, setGroups] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [quickAddInstitution, setQuickAddInstitution] = useState([]);
  const existingGroups = ["CARL", "CAUL", "OCUL", "COPPUL"];

  useEffect(() => {
    console.log(process.env.REACT_APP_API + "/institutions");
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      axios
        .get(process.env.REACT_APP_API + "/institutions", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          console.log("institutions", response);
          // setInstitutions(response.data);
          setInstitutions(
            response.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
          );
          // setCompletionData(response.data);
          // UNCOMMENT THIS TO ENABLE LOADING
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateInstitution = () => {
    const body = {
      name: name,
      region: region,
      province: province,
      groups: groups,
    };
    console.log("Create Institution", body);
    console.log("token", localStorage.token);
    try {
      axios
        .post(process.env.REACT_APP_API + "/institutions", body, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          console.log("institutions", response);
          setCreateInstitutionSuccess(true);
          // setOpenAddInstitution(false);

          // Reset fields
          setQuickAddInstitution("");
          setName("");
          setRegion("");
          setProvince("");
          setGroups([]);

          // Refresh data
          fetchData();
        })
        .catch((error) => {
          console.log("error", error);
          setCreateInstitutionError(true);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditInstitution = () => {
    const body = {
      name: name,
      region: region,
      province: province,
      groups: groups,
    };
    console.log("Edit Institution", body);
    console.log("token", localStorage.token);
    try {
      axios
        .patch(`${process.env.REACT_APP_API}/institutions/${id}`, body, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          console.log("institutions", response);
          setEditInstitutionSuccess(true);
          setOpenEditInstitution(false);

          // Reset fields
          setName("");
          setRegion("");
          setProvince("");
          setGroups([]);

          // Refresh data
          fetchData();
        })
        .catch((error) => {
          console.log("error", error);
          setEditInstitutionError(true);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteInstitution = () => {
    console.log("Delete Institution", id);
    // console.log("token", localStorage.token);
    try {
      axios
        .delete(`${process.env.REACT_APP_API}/institutions/${id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          setDeleteInstitutionSuccess(true);
          setOpenEditInstitution(false);

          // Reset fields
          setName("");
          setRegion("");
          setProvince("");
          setGroups([]);

          // Refresh data
          fetchData();
        })
        .catch((error) => {
          console.log("error", error);
          setDeleteInstitutionError(true);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickEditInstitution = (institution) => {
    console.log("Edit Institution", institution);
    setID(institution._id);
    setName(institution.name);
    setRegion(institution.region);
    setProvince(institution.province);
    setGroups(institution.groups);
    handleClickOpenEditInstitution();
  };

  const handleClickOpenAddInstitution = () => {
    setOpenAddInstitution(true);
  };

  const handleCloseAddInstitution = () => {
    setOpenAddInstitution(false);
  };

  const handleCreateInstitutionSuccess = () => {
    setCreateInstitutionSuccess(false);
  };

  const handleCreateInstitutionError = () => {
    setCreateInstitutionError(false);
  };

  const handleClickOpenEditInstitution = () => {
    setOpenEditInstitution(true);
  };

  const handleCloseEditInstitution = () => {
    setOpenEditInstitution(false);
  };

  const handleEditInstitutionSuccess = () => {
    setCreateInstitutionSuccess(false);
  };

  const handleEditInstitutionError = () => {
    setCreateInstitutionError(false);
  };

  const handleDeleteInstitutionSuccess = () => {
    setCreateInstitutionSuccess(false);
  };

  const handleDeleteInstitutionError = () => {
    setCreateInstitutionError(false);
  };

  const handleEmailChange = (event) => {
    setName(event.target.value);
  };

  const handleQuickAddChange = (event) => {
    setQuickAddInstitution(event.target.value);

    // populate the other fields with the values from the field, separated by commas
    const values = event.target.value.split(",");
    if (values.length === 5) {
      setName(values[0]);
      if (values[2] === "-1") {
        console.log("no additional group");
        setGroups([existingGroups[values[1] - 1]]);
      } else {
        setGroups([
          existingGroups[values[1] - 1],
          existingGroups[values[2] - 1],
        ]);
      }
      setRegion(values[3]);
      setProvince(values[4]);
    }
  };

  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };

  const handleProvinceChange = (event) => {
    setProvince(event.target.value);
  };

  const handleGroupsChange = (event, value) => {
    setGroups(value);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">Institution White List</Typography>
      <Typography variant="body1" className={classes.textSpacing}>
        Below is a list of the institutions that are currently allowed to access
        the platform. On this page you can add, deactivate, or remove
        institutions from the whitelist. Please note that deactivating an
        institution will prevent users from that institution from signing in,
        but will retain all of that institution's data in the system and
        accessible through downloads and on the Reports page.
      </Typography>
      <Button
        startIcon={<AddIcon />}
        color="primary"
        variant="contained"
        onClick={handleClickOpenAddInstitution}
        className={classes.textSpacing}
      >
        Add Institution
      </Button>

      {!loading ? (
        <TableContainer>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Institution</StyledTableCell>
                <StyledTableCell align="right">Region</StyledTableCell>
                <StyledTableCell align="right">Province</StyledTableCell>
                <StyledTableCell align="right">Group(s)</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {institutions.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.region}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.province}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.groups.join(", ")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <EditOutlined
                      onClick={() => handleClickEditInstitution(row)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            width: "50%",
            margin: "0 auto",
            marginTop: 20,
            // marginLeft: -250,
            textAlign: "center",
          }}
        >
          <Typography component="p" style={{ fontWeight: "lighter" }}>
            Loading institutions...
          </Typography>
          <CircularProgress style={{ margin: "3rem" }} />
        </div>
      )}

      <Dialog
        open={openAddInstitution}
        onClose={handleCloseAddInstitution}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Add Institution</DialogTitle>
        <Typography
          variant="body1"
          className={classes.textSpacing}
          style={{ marginLeft: 20, marginRight: 20 }}
        >
          Enter the institution's name, region, province, and group(s) to add.
          Please note that the institution name here will be viewable by users
          signing into the platform.
        </Typography>
        <div className={classes.dialog}>
          {/* <TextField
            autoFocus
            id="name"
            label="Quick Add (Name, Group1, Group2, Region, Province)"
            type="text"
            variant="outlined"
            fullWidth
            className={classes.maxWidth}
            value={quickAddInstitution}
            onChange={handleQuickAddChange}
          /> */}
          <TextField
            id="name"
            label="Name"
            type="text"
            variant="outlined"
            fullWidth
            className={classes.maxWidth}
            value={name}
            onChange={handleEmailChange}
          />
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.maxWidth}
          >
            <InputLabel id="region-label">Region</InputLabel>
            <Select
              labelId="region-label"
              id="region"
              label="Region"
              value={region}
              onChange={handleRegionChange}
            >
              <MenuItem value="Atlantic">Atlantic</MenuItem>
              <MenuItem value="BC">BC</MenuItem>
              <MenuItem value="National">National</MenuItem>
              <MenuItem value="Ontario">Ontario</MenuItem>
              <MenuItem value="Prairies">Prairies</MenuItem>
              <MenuItem value="Quebec">Quebec</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.maxWidth}
          >
            <InputLabel id="province-label">Province</InputLabel>
            <Select
              labelId="province-label"
              id="province"
              label="Province"
              value={province}
              onChange={handleProvinceChange}
            >
              <MenuItem value="NL">NL</MenuItem>
              <MenuItem value="PE">PE</MenuItem>
              <MenuItem value="NS">NS</MenuItem>
              <MenuItem value="NB">NB</MenuItem>
              <MenuItem value="QC">QC</MenuItem>
              <MenuItem value="ON">ON</MenuItem>
              <MenuItem value="MB">MB</MenuItem>
              <MenuItem value="SK">SK</MenuItem>
              <MenuItem value="AB">AB</MenuItem>
              <MenuItem value="BC">BC</MenuItem>
              <MenuItem value="YT">YT</MenuItem>
              <MenuItem value="NT">NT</MenuItem>
              <MenuItem value="NU">NU</MenuItem>
              <MenuItem value="CA">CA</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={existingGroups}
              getOptionLabel={(option) => option}
              //   defaultValue={[groups[0]]}
              filterSelectedOptions
              onChange={handleGroupsChange}
              // label="Group(s)"
              value={groups}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="filterSelectedOptions"
                  value={groups}
                  variant="outlined"
                  placeholder="Group(s)"
                  className={classes.maxWidth}
                />
              )}
            />
          </FormControl>
        </div>
        <DialogActions>
          <Button onClick={handleCloseAddInstitution} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateInstitution} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditInstitution}
        onClose={handleCloseEditInstitution}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Edit Institution</DialogTitle>
        <Typography
          variant="body1"
          className={classes.textSpacing}
          style={{ marginLeft: 20, marginRight: 20 }}
        >
          Edit the institution's name, region, province, and group(s) to change.
          Please note that the institution name here will be viewable by users
          signing into the platform.
        </Typography>
        <div className={classes.dialog}>
          <TextField
            autoFocus
            id="name"
            label="Name"
            type="text"
            variant="outlined"
            fullWidth
            className={classes.maxWidth}
            value={name}
            onChange={handleEmailChange}
          />
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.maxWidth}
          >
            <InputLabel id="region-label">Region</InputLabel>
            <Select
              labelId="region-label"
              id="region"
              label="Region"
              value={region}
              onChange={handleRegionChange}
            >
              <MenuItem value="Atlantic">Atlantic</MenuItem>
              <MenuItem value="BC">BC</MenuItem>
              <MenuItem value="National">National</MenuItem>
              <MenuItem value="Ontario">Ontario</MenuItem>
              <MenuItem value="Prairies">Prairies</MenuItem>
              <MenuItem value="Quebec">Quebec</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.maxWidth}
          >
            <InputLabel id="province-label">Province</InputLabel>
            <Select
              labelId="province-label"
              id="province"
              label="Province"
              value={province}
              onChange={handleProvinceChange}
            >
              <MenuItem value="NL">NL</MenuItem>
              <MenuItem value="PE">PE</MenuItem>
              <MenuItem value="NS">NS</MenuItem>
              <MenuItem value="NB">NB</MenuItem>
              <MenuItem value="QC">QC</MenuItem>
              <MenuItem value="ON">ON</MenuItem>
              <MenuItem value="MB">MB</MenuItem>
              <MenuItem value="SK">SK</MenuItem>
              <MenuItem value="AB">AB</MenuItem>
              <MenuItem value="BC">BC</MenuItem>
              <MenuItem value="YT">YT</MenuItem>
              <MenuItem value="NT">NT</MenuItem>
              <MenuItem value="NU">NU</MenuItem>
              <MenuItem value="CA">CA</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={existingGroups}
              getOptionLabel={(option) => option}
              //   defaultValue={[groups[0]]}
              filterSelectedOptions
              onChange={handleGroupsChange}
              // label="Group(s)"
              value={groups}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="filterSelectedOptions"
                  value={groups}
                  variant="outlined"
                  placeholder="Group(s)"
                  className={classes.maxWidth}
                />
              )}
            />
          </FormControl>
        </div>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button onClick={handleDeleteInstitution} color="danger">
            Deactivate
          </Button>
          <div>
            <Button onClick={handleCloseEditInstitution} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditInstitution} color="primary">
              Edit
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <ABRCSnackbar
        open={createInstitutionSuccess}
        handleClose={handleCreateInstitutionSuccess}
        severity="success"
        message="Institution created successfully!"
      />
      <ABRCSnackbar
        open={createInstitutionError}
        handleClose={handleCreateInstitutionError}
        severity="error"
        message="Error - Please try again!"
      />

      <ABRCSnackbar
        open={editInstitutionSuccess}
        handleClose={handleEditInstitutionSuccess}
        severity="success"
        message="Institution edited successfully!"
      />
      <ABRCSnackbar
        open={editInstitutionError}
        handleClose={handleEditInstitutionError}
        severity="error"
        message="Error - Could not Edit"
      />

      <ABRCSnackbar
        open={deleteInstitutionSuccess}
        handleClose={handleDeleteInstitutionSuccess}
        severity="success"
        message="Institution Deactivated!"
      />
      <ABRCSnackbar
        open={deleteInstitutionError}
        handleClose={handleDeleteInstitutionError}
        severity="error"
        message="Error - Could not Deactivate"
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    language: state.language,
  };
}

export default compose(
  withStyles(),
  connect(mapStateToProps, {})
)(Institutions);
