import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../assets/abrc-logo-full.png";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import {
  successfulAuth,
  successfulAuthOut,
  setLang,
  setYear,
  fetchInstitutions,
} from "../actions/postActions";
import ListSubheader from "@material-ui/core/ListSubheader";

// general configuration for languages
import configuration from "../form_schemas/configuration.json";
import configurationFR from "../form_schemas/configuration-fr.json";

import qs from "qs";
import institutions from "../pages/institutions";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "fixed",
    width: "100%",
    backgroundColor: "white",
    zIndex: 100,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {},
  bar: {
    backgroundColor: "white",
    boxShadow: "none",
  },
  logo: {
    flexGrow: 1,
    maxWidth: 100,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  languageSelect: {
    padding: "0px",
    margin: "0px",
  },
  yearSelect: {
    padding: "0px",
    marginRight: 20,
  },
  button: {
    marginRight: 20,
  },
  vertCenter: {
    display: "flex",
    alignItems: "baseline",
  },
  link: {
    textDecoration: "none",
  },
}));

function TopNav(props) {
  const classes = useStyles();
  const history = useHistory();
  const [lang, setLanguage] = React.useState(props.language);
  const validYears = configuration.ValidYears;
  const historicalYears = configuration.HistoricalYears;
  const [year, setYear] = React.useState(props.year);
  const [page, setPage] = React.useState("en");

  useEffect(() => {
    try {
      console.log("new Page", window.location.pathname.split("/").pop());
      setPage(window.location.pathname.split("/").pop());
      console.log(props.language);
    } catch (error) {
      console.log("error", error);
    }
  });

  useEffect(() => {
    // choose which form to import
    console.log("The URL has changed");
    let queryYear = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).yr;
    if (validYears.includes(queryYear)) {
      setYear(queryYear);
    } else {
      setYear(validYears.slice(-1)[0]);
    }
    // run through validYears and load in the appropriate form.
    // for (let i = 0; i < validYears.length + historicalYears.length; i++) {
    //   if (
    //     validYears.includes(queryYear) === validYears[i] ||
    //     historicalYears.includes(queryYear)
    //   ) {
    //     setYear(queryYear);
    //     break;
    //   } else if (!queryYear) {
    //     // always default to most recent year
    //     setYear(validYears.slice(-1)[0]);
    //     break;
    //   } else if (!validYears.includes(queryYear)) {
    //     // if query contains invalid year, display error
    //     console.log("not fetching forms");
    //     break;
    //   }
    // }
  }, [
    qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).yr,
  ]);

  const handleChange = (event) => {
    setLanguage(event.target.value);
    props.setLang(event.target.value);
  };

  const handleYearChange = (event) => {
    // when the year is changed, the page's url should change to use the correct metadata
    //  each form page should already listen to URL changes.
    setYear(event.target.value);
    props.setYear(event.target.value);
    history.push(
      "/" +
        window.location.pathname.split("/").pop() +
        "?yr=" +
        event.target.value
    );
  };

  const logout = () => {
    // destroy token on backend
    axios
      .post(
        process.env.REACT_APP_API + "/users/me/logout",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((response) => {
        localStorage.clear();
        props.successfulAuthOut();
        history.push("/login");
      })
      .catch((error) => {
        // console.log("error", error)
      });
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.bar}>
        <Toolbar className={classes.toolbar}>
          <Link id="logo-home" to={"/dashboard"} className={classes.link}>
            <img src={logo} className={classes.logo} />
          </Link>
          <div className={classes.vertCenter}>
            {props.user.loggedIn && (
              <>
                <Link id="home" to={"/dashboard"} className={classes.link}>
                  <Button className={classes.button}>
                    {props.language == "en" ? (
                      <>{configuration.General["home"]}</>
                    ) : (
                      <>{configurationFR.General["home"]}</>
                    )}
                  </Button>
                </Link>
                <Link id="login" to={"/login"} className={classes.link}>
                  <Button onClick={logout} className={classes.button}>
                    {props.language == "en" ? (
                      <>{configuration.General["logout"]}</>
                    ) : (
                      <>{configurationFR.General["logout"]}</>
                    )}
                  </Button>
                </Link>
              </>
            )}
            <FormControl
              margin="dense"
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={year}
                onChange={handleYearChange}
                className={classes.yearSelect}
              >
                {configuration.ValidYears.slice()
                  .reverse()
                  .map((year) => (
                    <MenuItem
                      styles={{ padding: 0, height: "100px" }}
                      value={year}
                    >
                      {year}
                    </MenuItem>
                  ))}
                {/* TODO: Re-enable one historical forms are ready to ship */}
                {/* <MenuItem disabled>
                  <Typography variant="overline">Historical Years</Typography>
                </MenuItem>
                {configuration.HistoricalYears.slice()
                  .reverse()
                  .map((year) => (
                    <MenuItem
                      styles={{ padding: 0, height: "100px" }}
                      value={year}
                    >
                      {year}
                    </MenuItem>
                  ))} */}
              </Select>
            </FormControl>

            <FormControl
              margin="dense"
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={lang}
                onChange={handleChange}
                className={classes.languageSelect}
              >
                <MenuItem styles={{ padding: 0, height: "100px" }} value={"en"}>
                  EN
                </MenuItem>
                <MenuItem value={"fr"}>FR</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    language: state.language,
    year: state.year,
  };
}

export default compose(
  withStyles(),
  connect(mapStateToProps, {
    successfulAuthOut,
    setLang,
    setYear,
  }),
  withRouter
)(TopNav);
