import React, { Component, useEffect } from "react";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { AccountBalance, Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { login } from "../actions/postActions";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import institutions from "../configuration/institutions.csv";
import { Redirect } from "react-router-dom";

import { successfulAuth } from "../actions/postActions";
import { CSVReader, readString, readRemoteFile } from "react-papaparse";

import { fetchForms, fetchInstitutions } from "../actions/postActions";

import configuration from "../form_schemas/configuration.json";
import configurationFR from "../form_schemas/configuration-fr.json";
import formSchemaImport from "../form_schemas/collections.json";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "90vh",
  },
  card: {
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  leftAlign: {
    textAlign: "left",
  },
}));

class Login extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      institution: "",
      email: "",
      password: "",
      showPassword: false,
      errorMessage: "",
      institutions: [],
      isAuthenticated: false,
      user: this.props.user,
      institutions: this.props.institutions,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.login = this.login.bind(this);
    this.loginReq = this.loginReq.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.institutions !== prevState.institutions ||
      nextProps.user !== prevState.user
    ) {
      console.log("next props", nextProps);
      // this.setState({
      //   institutions: nextProps.institutions,
      //   user: nextProps.user,
      // });
      return {
        user: nextProps.user,
        institutions: nextProps.institutions,
      };
    } else if (nextProps.institutions === null) {
      fetchInstitutions();
    }

    // No state update necessary
    return null;
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   console.log("next props", nextProps);
  //   return {
  //     user: nextProps.user,
  //     institutions: nextProps.institutions,
  //   };
  // }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  // const classes = useStyles();
  // const [values, setValues] = React.useState({
  //   institution: "",
  //   email: "",
  //   password: "",
  //   showPassword: false,
  //   errorMessage: "",
  //   insitutions: [],
  //   isAuthenticated: false,
  //   user: this.props.user,
  // });

  // useEffect(() => {
  //   // parse text file into array

  //   fetch(insitutions)
  //     .then((r) => r.text())
  //     .then((text) => {
  //       let temp = [];
  //       text.split("\n").forEach(function (item, index) {
  //         if (item) {
  //           temp.push(item);
  //         }
  //       });
  //       this.setState({ insitutions: temp });
  //     });
  // }, []);

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  validateInput = () => {
    if (!this.state.institution || this.state.institution.length === 0)
      if (this.props.language == "fr") {
        return configurationFR.Login["validate-institution"];
      } else {
        return configuration.Login["validate-institution"];
      }
    if (!this.state.email || this.state.email.length === 0)
      if (this.props.language == "fr") {
        return configurationFR.Login["validate-email"];
      } else {
        return configuration.Login["validate-email"];
      }
    if (!this.state.password || this.state.password.length === 0)
      if (this.props.language == "fr") {
        return configurationFR.Login["validate-password"];
      } else {
        return configuration.Login["validate-password"];
      }
    return "valid";
  };

  login = () => {
    var promise = new Promise((resolve) => {
      var payload = {
        email: this.state.email,
        password: this.state.password,
        institution: this.state.institution,
      };
      console.log("login payload", payload);
      this.loginReq(resolve, payload);
    });
    return promise;
  };

  loginReq = (resolve, payload) => {
    axios
      .post(process.env.REACT_APP_API + "/users/login", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.successful === false) {
          console.log("login failed");
          if (this.props.language == "fr") {
            this.setState({
              errorMessage: configurationFR.Login["error-invalid-credentials"],
            });
          } else {
            this.setState({
              errorMessage: configuration.Login["error-invalid-credentials"],
            });
          }

          // server's error message
          if (response.data.reason) {
            if (this.props.language == "fr") {
              resolve(configurationFR.Login["not-whitelisted"]);
            } else {
              resolve(configuration.Login["not-whitelisted"]);
            }
          }
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve("");
          console.log("Response");
          console.log(response.data);
          localStorage.setItem("token", response.data.token);
          console.log(response);
          this.props.successfulAuth(
            response.data.user.email,
            response.data.user._id,
            response.data.user.name,
            response.data.user.institution
          );
          // this.props.fetchForms(
          //   response.data.user.institution,
          //   formSchemaImport["FormID"].data
          // );

          // this.setState({ isAuthenticated: true });
          this.setState({ isAuthenticated: true });
          // set user info to redux
          // console.log('login succeeded')
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        console.log(error);
        // Should send a response of 400 ***
        console.log("login failed");
        if (this.props.language == "fr") {
          this.setState({
            errorMessage: configurationFR.Login["error-invalid-credentials"],
          });
        } else {
          this.setState({
            errorMessage: configuration.Login["error-invalid-credentials"],
          });
        }
        resolve(errorMsg);
      });
  };

  componentDidMount = () => {
    // read in institutions

    // this.setState({ insitutions: readString(institutions) });
    // console.log(readString(institutions));

    // try {
    //   readRemoteFile("../configuration/institutions.csv", {
    //     download: true,
    //     complete: (results) => {
    //       console.log(results);
    //     },
    //   });
    // } catch (error) {
    //   console.log(error);
    // }

    // fetch(institutions).then(function (response) {
    //   let reader = response.body.getReader();
    //   let decoder = new TextDecoder("utf-8");

    //   let data = decoder.decode(reader.read().value);
    //   console.log(data);
    //   this.setState({ institutions: data });
    //   // reader.read().then(function (result) {
    //   //   let data = decoder.decode(result.value);
    //   //   console.log(data);
    //   //   this.setState({ institutions: "Invalid Login Credentials" });
    //   //   // this.setState({
    //   //   //   institutions: data,
    //   //   // });
    //   // });
    // });
    console.log("Fetching institution");
    this.props.fetchInstitutions();
    this.setState({ institutions: this.props.institutions });

    // this.interval = setInterval(() => {
    //   this.props.fetchInstitutions();
    //   console.log(this.props.institutions);
    //   console.log(this.props.language);
    // }, 5000);

    // fetch(institutions)
    //   .then((r) => r.text())
    //   .then((text) => {
    //     let temp = [];
    //     text.split("\n").forEach(function (item, index) {
    //       if (item) {
    //         // only accept institution name, not group.
    //         temp.push(item.split(",")[0]);
    //       }
    //     });
    //     console.log(temp);
    //     this.setState({ institutions: temp });
    //   });
  };

  render() {
    const classes = {
      root: {},
      card: {},
      leftAlign: {
        textAlign: "left",
      },
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "90vh",
        }}
        className={classes.root}
      >
        {this.props.user.loggedIn && <Redirect to="/dashboard" />}
        <Grid
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          <Grid item>
            <div />
          </Grid>
          <Card
            style={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              padding: 30,
              minWidth: 400,
            }}
          >
            <Grid style={{}} container direction={"column"} spacing={3}>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="h4">
                  {this.props.language == "en" ? (
                    <>{configuration.Login["page-title"]}</>
                  ) : (
                    <>{configurationFR.Login["page-title"]}</>
                  )}
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="body2" style={{ color: "gray" }}>
                  {this.props.language == "en" ? (
                    <>{configuration.Login["page-description"]}</>
                  ) : (
                    <>{configurationFR.Login["page-description"]}</>
                  )}
                </Typography>
              </Grid>
              {this.state.errorMessage.length > 0 && (
                <Grid item style={{ textAlign: "left" }}>
                  <Typography variant="body2" style={{ color: "red" }}>
                    {this.state.errorMessage}{" "}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>
                    {this.props.language == "en" ? (
                      <>{configuration.Login["institution-placeholder"]}</>
                    ) : (
                      <>{configurationFR.Login["institution-placeholder"]}</>
                    )}
                  </InputLabel>
                  {this.state.institutions &&
                    this.state.institutions.length > 0 && (
                      <Select
                        labelWidth={160}
                        style={{ textAlign: "left" }}
                        value={this.state.institution}
                        onChange={this.handleChange("institution")}
                        startAdornment={
                          <InputAdornment position="start">
                            <AccountBalance />
                          </InputAdornment>
                        }
                      >
                        {this.state.institutions.map((item, key) => {
                          return (
                            <MenuItem key={item.name} value={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>
                    {this.props.language == "en" ? (
                      <>{configuration.Login["email-placeholder"]}</>
                    ) : (
                      <>{configurationFR.Login["email-placeholder"]}</>
                    )}
                  </InputLabel>
                  <OutlinedInput
                    type="text"
                    labelWidth={70}
                    value={this.state.email}
                    onChange={this.handleChange("email")}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>
                    {this.props.language == "en" ? (
                      <>{configuration.Login["password-placeholder"]}</>
                    ) : (
                      <>{configurationFR.Login["password-placeholder"]}</>
                    )}
                  </InputLabel>
                  <OutlinedInput
                    type={this.state.showPassword ? "text" : "password"}
                    labelWidth={70}
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item container justify="center">
                {/* <Link style={{ textDecoration: "none" }}> */}
                <Button variant="outlined" color="primary" onClick={this.login}>
                  {this.props.language == "en" ? (
                    <>{configuration.Login["button-text"]}</>
                  ) : (
                    <>{configurationFR.Login["button-text"]}</>
                  )}
                </Button>
                {/* </Link> */}
              </Grid>
            </Grid>
          </Card>
          <Link
            id="ResetPassword"
            to={"/forgot"}
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="body2"
              style={{ color: "gray", marginTop: 25 }}
            >
              {this.props.language == "en" ? (
                <>{configuration.Login["reset-password"]}</>
              ) : (
                <>{configurationFR.Login["reset-password"]}</>
              )}
            </Typography>
          </Link>
          <Grid item>
            <div />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    language: state.language,
    institutions: state.institutions,
  };
};

export default connect(mapStateToProps, {
  successfulAuth,
  fetchForms,
  fetchInstitutions,
})(Login);
