import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { AccountBalance, Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { login } from "../actions/postActions";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { successfulAuth } from "../actions/postActions";

// load in whitelisted institutions
import institutions from "../configuration/institutions.csv";

import configuration from "../form_schemas/configuration.json";
import configurationFR from "../form_schemas/configuration-fr.json";
import formSchemaImport from "../form_schemas/collections.json";
import { fetchForms, fetchInstitutions } from "../actions/postActions";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "95vh",
    // paddingTop: "20px",
    // marginBottom: "-20px",
  },
  card: {
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  leftAlign: {
    textAlign: "left",
  },
}));

function Signup(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    institution: "",
    name: "",
    email: "",
    password: "",
    showPassword: false,
    errorMessage: "",
    institutions: [],
  });

  useEffect(() => {
    setValues({ ...values, institutions: props.institutions });
    console.log("next props signups", props);
    if (props.institutions.length < 2) {
      console.log("fetching institutions", props.institutions);
      props.fetchInstitutions();
    }
  }, [props.institutions]);

  // useEffect(() => {
  //   // parse text file into array
  //   fetch(institutions)
  //     .then((r) => r.text())
  //     .then((text) => {
  //       let temp = [];
  //       text.split("\n").forEach(function (item, index) {
  //         if (item) {
  //           // only accept institution name, not group.
  //           temp.push(item.split(",")[0]);
  //         }
  //       });
  //       setValues({ ...values, institutions: temp });
  //     });
  // }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateInput = () => {
    if (!values.institution || values.institution.length === 0)
      if (props.language == "fr") {
        return configurationFR.SignUp["validate-institution"];
      } else {
        return configuration.SignUp["validate-institution"];
      }
    if (!values.email || values.email.length === 0)
      if (props.language == "fr") {
        return configurationFR.SignUp["validate-email"];
      } else {
        return configuration.SignUp["validate-email"];
      }
    if (!values.password || values.password.length === 0)
      if (props.language == "fr") {
        return configurationFR.SignUp["validate-password"];
      } else {
        return configuration.SignUp["validate-password"];
      }
    return "valid";
  };

  const signUp = () => {
    var promise = new Promise((resolve) => {
      var payload = {
        institution: values.institution,
        name: values.name,
        email: values.email,
        password: values.password,
      };
      signUpReq(resolve, payload);
    });
    return promise;
  };

  const signUpReq = (resolve, payload) => {
    console.log(payload);
    axios
      .post(process.env.REACT_APP_API + "/users", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response)
        // console.log("post request")
        if (response.data.successful === false) {
          // console.log("signup failed")
          var errorMsg = response.data.reason;
          resolve(errorMsg);

          if (props.language == "fr") {
            setValues({
              ...values,
              errorMessage: configurationFR.SignUp["error-invalid-credentials"],
            });
          } else {
            setValues({
              ...values,
              errorMessage: configuration.SignUp["error-invalid-credentials"],
            });
          }
        } else {
          resolve("");
          localStorage.setItem("token", response.data.token);
          // console.log(response);
          // console.log('signup succeeded!')
          // props.fetchForms(
          //   response.data.user.institution,
          //   formSchemaImport["FormID"].data
          // );
          props.successfulAuth(
            response.data.user.email,
            response.data.user._id,
            response.data.user.name,
            response.data.user.institution
          );
          // set user info to redux
          if (props.language == "fr") {
            setValues({
              ...values,
              errorMessage: configurationFR.SignUp["success"],
            });
          } else {
            setValues({
              ...values,
              errorMessage: configuration.SignUp["success"],
            });
          }
          props.history.push("dashboard");
        }
      })
      .catch((error) => {
        // console.log(error)
        // console.log("post request error")
        var errorMsg = "Missing data";
        // Should send a response of 400 ***
        resolve(errorMsg);
        console.log(error);
        if (props.language == "fr") {
          setValues({
            ...values,
            errorMessage: configurationFR.SignUp["not-whitelisted"],
          });
        } else {
          setValues({
            ...values,
            errorMessage: configuration.SignUp["not-whitelisted"],
          });
        }
        // setValues({ ...values, errorMessage: error.response.data });
      });
  };

  // const signUp = () => {
  //   console.log({
  //     institution: values.institution,
  //     name: values.name,
  //     email: values.email,
  //     password: values.password,
  //   });
  //   const validation = validateInput();
  //   if (validation === "valid") {
  //     axios
  //       .post(process.env.REACT_APP_API + "/users", {
  //         headers: { "Access-Control-Allow-Origin": "*" },
  //         institution: values.institution,
  //         name: values.name,
  //         email: values.email,
  //         password: values.password,
  //       })
  //       .then(function (response) {
  //         console.log(response);
  //         localStorage.setItem("token", response.data.token);
  //         localStorage.setItem("dGF0ZXJ0b3Q", true);
  //         setValues({ ...values, errorMessage: "Success!" });
  //         props.history.push("dashboard");
  //       })
  //       .catch(function (error) {
  //         //   console.log(error.data);
  //         console.log(error.response.data);

  //         setValues({ ...values, errorMessage: error.response.data });
  //       });
  //   } else {
  //     setValues({ ...values, errorMessage: validation });
  //   }
  // };

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="center"
        direction="column"
        justify="space-between"
      >
        <Grid item>
          <div />
        </Grid>
        <Card style={{ padding: 30, minWidth: 400 }} className={classes.card}>
          <Grid container direction={"column"} spacing={3}>
            <Grid item className={classes.leftAlign}>
              <Typography variant="h4">
                {props.language == "en" ? (
                  <>{configuration.SignUp["page-title"]}</>
                ) : (
                  <>{configurationFR.SignUp["page-title"]}</>
                )}
              </Typography>
            </Grid>
            <Grid item className={classes.leftAlign}>
              <Typography variant="body2" style={{ color: "gray" }}>
                {props.language == "en" ? (
                  <>{configuration.SignUp["page-description"]}</>
                ) : (
                  <>{configurationFR.SignUp["page-description"]}</>
                )}
              </Typography>
            </Grid>
            {values.errorMessage.length > 0 && (
              <Grid
                item
                className={classes.leftAlign}
                style={{ maxWidth: 400 }}
              >
                {values.errorMessage === "Success!" ||
                values.errorMessage === "Succès!" ? (
                  <Typography variant="body2" style={{ color: "green" }}>
                    {values.errorMessage}{" "}
                  </Typography>
                ) : (
                  <Typography variant="body2" style={{ color: "red" }}>
                    {values.errorMessage}{" "}
                  </Typography>
                )}
              </Grid>
            )}
            <Grid item>
              <FormControl fullWidth variant="outlined">
                <InputLabel>
                  {props.language == "en" ? (
                    <>{configuration.SignUp["institution-placeholder"]}</>
                  ) : (
                    <>{configurationFR.SignUp["institution-placeholder"]}</>
                  )}
                </InputLabel>
                <Select
                  labelWidth={160}
                  style={{ textAlign: "left" }}
                  value={values.institution}
                  onChange={handleChange("institution")}
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountBalance />
                    </InputAdornment>
                  }
                >
                  {values.institutions.map((item, key) => {
                    return (
                      <MenuItem key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth variant="outlined">
                <InputLabel>
                  {props.language == "en" ? (
                    <>{configuration.SignUp["name-placeholder"]}</>
                  ) : (
                    <>{configurationFR.SignUp["name-placeholder"]}</>
                  )}
                </InputLabel>
                <OutlinedInput
                  type="text"
                  labelWidth={70}
                  value={values.name}
                  onChange={handleChange("name")}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth variant="outlined">
                <InputLabel>
                  {props.language == "en" ? (
                    <>{configuration.SignUp["email-placeholder"]}</>
                  ) : (
                    <>{configurationFR.SignUp["email-placeholder"]}</>
                  )}
                </InputLabel>
                <OutlinedInput
                  type="text"
                  labelWidth={70}
                  value={values.email}
                  onChange={handleChange("email")}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth variant="outlined">
                <InputLabel>
                  {props.language == "en" ? (
                    <>{configuration.SignUp["password-placeholder"]}</>
                  ) : (
                    <>{configurationFR.SignUp["password-placeholder"]}</>
                  )}
                </InputLabel>
                <OutlinedInput
                  type={values.showPassword ? "text" : "password"}
                  labelWidth={70}
                  value={values.password}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>

            <Grid item container justify="center">
              <Link style={{ textDecoration: "none" }} to={""}>
                <Button variant="outlined" color="primary" onClick={signUp}>
                  {props.language == "en" ? (
                    <>{configuration.SignUp["button-text"]}</>
                  ) : (
                    <>{configurationFR.SignUp["button-text"]}</>
                  )}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Card>
        <Grid item>
          <div />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    language: state.language,
    institutions: state.institutions,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    successfulAuth,
    fetchForms,
    fetchInstitutions,
  })(Signup)
);
