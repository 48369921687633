import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import qs from "qs";
import { connect } from "react-redux";
import compose from "recompose/compose";
import {
  successfulAuth,
  successfulAuthOut,
  fetchForms,
} from "../actions/postActions";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 280,
    marginTop: 64,
    padding: 40,
  },
  verticalSpacing: {
    marginTop: 20,
  },
}));

const isInteger = (value) => {
  return !isNaN(value);
};

function SalaryCSV(props) {
  const [year, setYear] = useState(props.year);
  const [institution, setInstitution] = useState("");
  const [email, setEmail] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableError, setTableError] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    let queryYear = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).yr;

    let institution = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).inst;

    let email = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).email;
    console.log(queryYear);
    setInstitution(institution);
    setYear(queryYear);
    setEmail(email);
    console.log(institution);
    loadCurrentForm(queryYear, institution);
  }, []);

  const loadCurrentForm = (date, institution) => {
    // Fetch the salary CSV for an institution for a given year.

    axios
      .get(process.env.REACT_APP_API + "/salary", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          institution: institution,
          id: date,
        },
      })
      .then(function (response) {
        // populate current form with this version
        console.log("formdata from axios", response.data);
        setCsvData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        //   console.log(error.data);
        console.log(error);
        setLoading(true);
      });
  };

  const checkHeader = (value_raw, pos) => {
    let value = value_raw.trim();

    // return a table fragment depending on if this passes or not
    switch (pos) {
      case 0:
        if (value === "Staff ID" || value === "No d'ident.") {
          return <TableCell>{value}</TableCell>;
        } else {
          // setTableError(true);
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {props.language === "en" && (
                <Tooltip title="Must be 'Staff ID'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {props.language === "fr" && (
                <Tooltip title="Doit être 'No d'ident.'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
            </TableCell>
          );
        }
      case 1:
        if (value === "Salary" || value === "Salaire") {
          return <TableCell>{value}</TableCell>;
        } else {
          // setTableError(true);
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {props.language === "en" && (
                <Tooltip title="Must be 'Salary'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {props.language === "fr" && (
                <Tooltip title="Doit être 'Salaire'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
            </TableCell>
          );
        }
      case 2:
        if (value === "Category" || value === "Catégorie du poste") {
          return <TableCell>{value}</TableCell>;
        } else {
          // setTableError(true);
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {props.language === "en" && (
                <Tooltip title="Must be 'Category'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {props.language === "fr" && (
                <Tooltip title="Doit être 'Catégorie du poste'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
            </TableCell>
          );
        }
      case 3:
        if (
          value === "Professional Experience (Years)" ||
          value === "Années d'expérience professionnelle"
        ) {
          return <TableCell>{value}</TableCell>;
        } else {
          // setTableError(true);
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {props.language === "en" && (
                <Tooltip title="Must be 'Professional Experience (Years)'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {props.language === "fr" && (
                <Tooltip title="Doit être 'Années d'expérience professionnelle'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
            </TableCell>
          );
        }
      case 4:
        if (
          value === "Professional Experience in Reporting Insitution (Years)" ||
          value ===
            "Professional Experience in Reporting Institution (Years)" ||
          value === "Années d'expérience professionnelle dans l'institution"
        ) {
          return <TableCell>{value}</TableCell>;
        } else {
          // setTableError(true);
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {props.language === "en" && (
                <Tooltip title="Must be 'Professional Experience in Reporting Insitution (Years)'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {props.language === "fr" && (
                <Tooltip title="Doit être 'Années d'expérience professionnelle dans l'institution'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
            </TableCell>
          );
        }
      default:
        return <TableCell>{value}</TableCell>;
    }
  };

  const checkValue = (value_raw, expectedType) => {
    let value = value_raw.trim();

    console.log(value, expectedType, typeof value, props.language);
    if (value !== undefined && value !== null) {
      if (expectedType === "number") {
        if (isInteger(value) && value >= 0 && value !== "") {
          return <TableCell align="right">{value}</TableCell>;
        } else {
          if (!tableError) {
            setTableError(true);
          }
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {props.language === "en" && (
                <Tooltip title="Must be a positive number (ex. 42133.22)">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {props.language === "fr" && (
                <Tooltip title="Doit être un nombre positif (ex. 42133.22)">
                  {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                </Tooltip>
              )}
            </TableCell>
          );
        }
      } else if (typeof value === expectedType) {
        return <TableCell align="right">{value}</TableCell>;
      } else {
        if (!tableError) {
          setTableError(true);
        }
        // setTableError(true);
        return (
          <TableCell
            align="right"
            style={{
              backgroundColor: "#FDEDEE",
              borderRadius: 5,
            }}
          >
            {props.language === "en" && (
              <Tooltip title="Must be a string (ex. 'word')">
                {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
              </Tooltip>
            )}
            {props.language === "fr" && (
              <Tooltip title="Doit être une chaîne (ex. 'mot')">
                {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
              </Tooltip>
            )}
          </TableCell>
        );
      }
    }
  };

  const csvBuilder = (head, data) => {
    return (
      <div className={classes.verticalSpacing}>
        <TableContainer
          style={{
            borderStyle: "solid",
            borderColor: "rgba(0,0,0,0.3)",
            borderRadius: "10px",
            borderWidth: "1px",
            padding: 5,
          }}
        >
          <Table style={{}} aria-label="simple table">
            <TableHead>
              {head && (
                <TableRow>
                  {/* must be "Staff ID" */}
                  {checkHeader(head.data[0], 0)}
                  {checkHeader(head.data[1], 1)}
                  {checkHeader(head.data[2], 2)}
                  {checkHeader(head.data[3], 3)}
                  {checkHeader(head.data[4], 4)}
                </TableRow>
              )}
            </TableHead>
            {data && (
              <TableBody>
                {data.map(
                  (row) =>
                    row.data.length > 1 && (
                      <TableRow key={row.name}>
                        {checkValue(row.data[0], "string")}
                        {checkValue(row.data[1], "number")}
                        {checkValue(row.data[2], "string")}
                        {checkValue(row.data[3], "number")}
                        {checkValue(row.data[4], "number")}
                      </TableRow>
                    )
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {/* <Button style={{ marginTop: 5 }} onClick={removeClick}>
          Remove
        </Button> */}
        {tableError && (
          <>
            {props.language === "en" && (
              <div style={{ color: "red", marginTop: 10 }}>
                Please change the fields in red to the correct format and
                reupload the CSV!
              </div>
            )}
            {props.language === "fr" && (
              <div style={{ color: "red", marginTop: 10 }}>
                SVP modifier les champs en rouge au format correct et recharger
                le CSV !
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">Salary CSV</Typography>
      <Typography className={classes.verticalSpacing} variant="body1">
        Year: <b>{year}</b> | Institution: <b>{institution}</b> | Submitted by:{" "}
        <b>{email}</b>
      </Typography>
      {loading && <CircularProgress className={classes.verticalSpacing} />}
      {!loading && csvBuilder(csvData[0], csvData.slice(1))}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    language: state.language,
  };
}

export default compose(
  withStyles(),
  connect(mapStateToProps, {
    successfulAuthOut,
  })
)(withRouter(SalaryCSV));
