import {
  AUTH_SUCCESS,
  LANGUAGE,
  FORM_STATUS,
  INSTITUTIONS,
} from "../constants/action-types";
import configuration from "../form_schemas/configuration.json";

const initialState = {
  user: {
    username: "na",
    institution: "na",
    password: "na",
    name: "na",
    loggedIn: false,
  },
  institutions: ["Institutions Loading..."],
  language: "en",
  isAdmin: false,
  formstatus: [],
  year: configuration.ValidYears.slice(-1)[0],
};

function rootReducer(state = initialState, action) {
  if (action.type === AUTH_SUCCESS) {
    return Object.assign({}, state, {
      user: action.payload,
    });
  }
  if (action.type === FORM_STATUS) {
    return Object.assign({}, state, {
      formstatus: action.payload,
    });
  }
  if (action.type === LANGUAGE) {
    return Object.assign({}, state, {
      language: action.payload,
    });
  }
  if (action.type === INSTITUTIONS) {
    return Object.assign({}, state, {
      institutions: action.payload,
    });
  }
  return state;
}

export default rootReducer;
