import React, { Component } from "react";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { AccountBalance, Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { login } from "../actions/postActions";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import institutions from "../configuration/institutions.csv";
import { Redirect } from "react-router-dom";

import { successfulAuth } from "../actions/postActions";
import { CSVReader, readString, readRemoteFile } from "react-papaparse";

import { fetchForms } from "../actions/postActions";

import configuration from "../form_schemas/configuration.json";
import configurationFR from "../form_schemas/configuration-fr.json";
import formSchemaImport from "../form_schemas/collections.json";

import qs from "qs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    minHeight: "90vh",
  },
  card: {
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  leftAlign: {
    textAlign: "left",
  },
}));

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      institution: "",
      email: "",
      password: "",
      passwordAgain: "",
      showPassword: false,
      showPasswordAgain: false,
      errorMessage: "",
      institutions: [],
      isAuthenticated: false,
      user: this.props.user,
      resetSuccess: false,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.reset = this.reset.bind(this);
    this.resetReq = this.resetReq.bind(this);
  }

  componentWillReceiveProps(nextState) {
    // load in all calendar events on init
    // console.log('next state', nextState.admin)
    this.setState({
      user: nextState.user,
    });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleClickShowPasswordAgain = () => {
    this.setState((state) => ({ showPasswordAgain: !state.showPasswordAgain }));
  };
  // const classes = useStyles();
  // const [values, setValues] = React.useState({
  //   institution: "",
  //   email: "",
  //   password: "",
  //   showPassword: false,
  //   errorMessage: "",
  //   insitutions: [],
  //   isAuthenticated: false,
  //   user: this.props.user,
  // });

  // useEffect(() => {
  //   // parse text file into array

  //   fetch(insitutions)
  //     .then((r) => r.text())
  //     .then((text) => {
  //       let temp = [];
  //       text.split("\n").forEach(function (item, index) {
  //         if (item) {
  //           temp.push(item);
  //         }
  //       });
  //       this.setState({ insitutions: temp });
  //     });
  // }, []);

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  validateInput = () => {
    if (!this.state.institution || this.state.institution.length === 0)
      if (this.props.language == "fr") {
        return configurationFR.ResetPassword["validate-institution"];
      } else {
        return configuration.ResetPassword["validate-institution"];
      }
    if (!this.state.email || this.state.email.length === 0)
      if (this.props.language == "fr") {
        return configurationFR.ResetPassword["validate-email"];
      } else {
        return configuration.ResetPassword["validate-email"];
      }
    if (!this.state.password || this.state.password.length === 0)
      if (this.props.language == "fr") {
        return configurationFR.ResetPassword["validate-password"];
      } else {
        return configuration.ResetPassword["validate-password"];
      }
    return "valid";
  };

  reset = () => {
    // check that passwords match or give error.
    if (this.state.password !== this.state.passwordAgain) {
      if (this.props.language == "fr") {
        this.setState({
          errorMessage: configurationFR.ResetPassword["error-pwd-mismatch"],
        });
      } else {
        this.setState({
          errorMessage: configuration.ResetPassword["error-pwd-mismatch"],
        });
      }
    } else {
      var promise = new Promise((resolve) => {
        // get token and ID from URL

        var payload = {
          token: qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
          }).token,
          userId: qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
          }).id,
          password: this.state.password,
        };
        console.log(payload);
        this.resetReq(resolve, payload);
      });
      return promise;
    }
  };

  // login = () => {
  //   var promise = new Promise((resolve) => {
  //     var payload = {
  //       email: this.state.email,
  //       password: this.state.password,
  //     };
  //     console.log(payload);
  //     this.loginReq(resolve, payload);
  //   });
  //   return promise;
  // };

  resetReq = (resolve, payload) => {
    axios
      .post(process.env.REACT_APP_API + "/users/resetpassword", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.successful === false) {
          console.log("reset failed");
          if (this.props.language == "fr") {
            this.setState({
              errorMessage: configurationFR.ResetPassword["error-failed"],
            });
          } else {
            this.setState({
              errorMessage: configuration.ResetPassword["error-failed"],
            });
          }

          // server's error message
          if (response.data.reason) {
            if (this.props.language == "fr") {
              resolve(configurationFR.ResetPassword["not-whitelisted"]);
            } else {
              resolve(configuration.ResetPassword["not-whitelisted"]);
            }
          }
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve("");
          console.log(response);
          if (this.props.language == "fr") {
            resolve(configurationFR.ResetPassword["success"]);
          } else {
            resolve(configuration.ResetPassword["success"]);
          }
          // success! user needs to login again
          setTimeout(() => {
            this.setState({
              resetSuccess: true,
            });
          }, "2000");

          // this.setState({ isAuthenticated: true });
          // set user info to redux
          // console.log('login succeeded')
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        console.log(error);
        // Should send a response of 400 ***
        if (this.props.language == "fr") {
          this.setState({
            errorMessage: configurationFR.ResetPassword["error-failed"],
          });
        } else {
          this.setState({
            errorMessage: configuration.ResetPassword["error-failed"],
          });
        }
        resolve(errorMsg);
      });
  };

  componentWillMount = () => {
    // read in institutions
    // this.setState({ insitutions: readString(institutions) });
    // console.log(readString(institutions));
    // try {
    //   readRemoteFile("../configuration/institutions.csv", {
    //     download: true,
    //     complete: (results) => {
    //       console.log(results);
    //     },
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
    // fetch(institutions).then(function (response) {
    //   let reader = response.body.getReader();
    //   let decoder = new TextDecoder("utf-8");
    //   let data = decoder.decode(reader.read().value);
    //   console.log(data);
    //   this.setState({ institutions: data });
    //   // reader.read().then(function (result) {
    //   //   let data = decoder.decode(result.value);
    //   //   console.log(data);
    //   //   this.setState({ institutions: "Invalid Login Credentials" });
    //   //   // this.setState({
    //   //   //   institutions: data,
    //   //   // });
    //   // });
    // });
  };

  render() {
    const classes = {
      root: {},
      card: {},
      leftAlign: {
        textAlign: "left",
      },
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "90vh",
        }}
        className={classes.root}
      >
        {this.state.user.loggedIn && <Redirect to="/dashboard" />}
        <Grid
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          <Grid item>
            <div />
          </Grid>
          <Card
            style={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              padding: 30,
              minWidth: 400,
            }}
          >
            <Grid style={{}} container direction={"column"} spacing={3}>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="h4">
                  {this.props.language == "en" ? (
                    <>{configuration.ResetPassword["page-title"]}</>
                  ) : (
                    <>{configurationFR.ResetPassword["page-title"]}</>
                  )}
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="body2" style={{ color: "gray" }}>
                  {this.props.language == "en" ? (
                    <>{configuration.ResetPassword["page-description"]}</>
                  ) : (
                    <>{configurationFR.ResetPassword["page-description"]}</>
                  )}
                </Typography>
              </Grid>
              {this.state.errorMessage.length > 0 && (
                <Grid item style={{ textAlign: "left" }}>
                  <Typography variant="body2" style={{ color: "red" }}>
                    {this.state.errorMessage}{" "}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>
                    {this.props.language == "en" ? (
                      <>{configuration.ResetPassword["password-placeholder"]}</>
                    ) : (
                      <>
                        {configurationFR.ResetPassword["password-placeholder"]}
                      </>
                    )}
                  </InputLabel>
                  <OutlinedInput
                    type={this.state.showPassword ? "text" : "password"}
                    labelWidth={this.props.language == "en" ? 120 : 180}
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>
                    {this.props.language == "en" ? (
                      <>
                        {
                          configuration.ResetPassword[
                            "password-placeholder-again"
                          ]
                        }
                      </>
                    ) : (
                      <>
                        {
                          configurationFR.ResetPassword[
                            "password-placeholder-again"
                          ]
                        }
                      </>
                    )}
                  </InputLabel>
                  <OutlinedInput
                    type={this.state.showPasswordAgain ? "text" : "password"}
                    labelWidth={this.props.language == "en" ? 170 : 230}
                    value={this.state.passwordAgain}
                    onChange={this.handleChange("passwordAgain")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPasswordAgain}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPasswordAgain ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item container justify="center">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.reset}
                  disabled={
                    (this.state.password.length > 5 &&
                      this.state.passwordAgain.length) > 5
                      ? false
                      : true
                  }
                >
                  {this.props.language == "en" ? (
                    <>{configuration.ResetPassword["button-text"]}</>
                  ) : (
                    <>{configurationFR.ResetPassword["button-text"]}</>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Link
            id="ResetPassword"
            to={"/reset"}
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="body2"
              style={{ color: "gray", marginTop: 25 }}
            >
              {this.props.language == "en" ? (
                <>{configuration.ResetPassword["reset-password"]}</>
              ) : (
                <>{configurationFR.ResetPassword["reset-password"]}</>
              )}
            </Typography>
          </Link>
          <Grid item>
            <div />
          </Grid>
        </Grid>
        {this.state.resetSuccess && <Redirect to="/login" />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    language: state.language,
  };
};

export default connect(mapStateToProps, {
  successfulAuth,
  fetchForms,
})(ResetPassword);
