import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Navbar from "./components/side_nav";
import TopNav from "./components/top_nav";
import Footer from "./components/footer";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Reports from "./pages/reports";
import ForgotPassword from "./pages/forgot_password";
import ResetPassword from "./pages/reset_password";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Dashboard from "./pages/dashboard";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import "./App.css";
import KeyStatistics from "./pages/key_statistics";
import Collections from "./pages/collections";
import History from "./pages/history";
import Expenditures from "./pages/expenditures";
import Facilities from "./pages/facilities";
import Services from "./pages/services";
import Enrolment from "./pages/student_enrolment";
import Institution from "./pages/institutional_information";
import Personnel from "./pages/personnel";
import Institutions from "./pages/institutions";
import Whitelist from "./pages/whitelist";
import SalaryCSV from "./pages/salary_csv";
import CircularProgress from "@material-ui/core/CircularProgress";

import { successfulAuth, fetchForms } from "./actions/postActions";
import formSchemaImport from "./form_schemas/collections-fr.json";

import axios from "axios";

const theme = createMuiTheme({
  palette: {
    primary: {
      // CARL Blue
      main: "#132C87",
    },
    secondary: {
      // Secondary Yellow
      main: "#F39E1A",
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      isAuthenticated: false,
      user: this.props.user,
      loading: true,
    };
    // console.log("backend connection: ", process.env.REACT_APP_DOMAIN)
  }
  // useEffect(() => {
  //   console.log("run check login", window.location.pathname.split("/").pop());
  //   checkLoginStatus();
  // }, []);

  // const [values, setValues] = React.useState({
  //   isLoggedIn: false,
  //   loadingUser: false,
  // });

  /**
   * `PrivateRoute` is a higher-order component for handling protected route scenarios in React Router.
   * It checks if the user is logged in and not loading. If true, it renders the passed component.
   * If false, it redirects the user to the Login page.
   *
   * @param {object} component - The component to render if the user is authenticated.
   * @param {object} rest - Any additional props that should be passed to the Route component.
   *
   * @returns {object} A Route component that either renders the passed component or redirects to the login page.
   */
  PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          this.props.user.loggedIn && !this.state.loading ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  };

  /**
   * `PrivateAdminRoute` is a higher-order component for handling protected route scenarios in React Router.
   * It checks if the user is logged in, not loading, and belongs to the institution "CARL-ABRC".
   * If all conditions are true, it renders the passed component.
   * If any condition is false, it redirects the user to the Dashboard page.
   *
   * @param {object} component - The component to render if the user is authenticated and belongs to the "CARL-ABRC" institution.
   * @param {object} rest - Any additional props that should be passed to the Route component.
   *
   * @returns {object} A Route component that either renders the passed component or redirects to the dashboard page.
   */
  PrivateAdminRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          this.props.user.loggedIn &&
          !this.state.loading &&
          this.props.user.institution === "CARL-ABRC" ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/dashboard", state: { from: props.location } }}
            />
          )
        }
      />
    );
  };

  checkLoginStatus = () => {
    const token = localStorage.token;
    console.log(token);
    axios
      .get(process.env.REACT_APP_API + "/users/me", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        console.log("successful login", response);
        this.props.successfulAuth(
          response.data.email,
          response.data._id,
          response.data.name,
          response.data.institution
        );
        this.props.fetchForms(
          response.data.institution,
          formSchemaImport["FormID"].data
        );
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.checkLoginStatus();
  }

  componentWillReceiveProps(nextState) {
    // load in all calendar events on init
    // console.log('next state', nextState.admin)
    this.setState({
      admin: nextState.admin,
    });
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <div>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: 500,
                height: 200,
                marginTop: -100,
                marginLeft: -250,
                textAlign: "center",
              }}
            >
              <Typography component="h1" style={{ fontSize: 25 }}>
                Welcome to CARL-ABRC Stats
              </Typography>
              <Typography component="p" style={{ fontWeight: "lighter" }}>
                Loading in your information.
              </Typography>
              <CircularProgress style={{ margin: "3rem" }} />
            </div>
          </div>
        ) : (
          <ThemeProvider theme={theme}>
            <div className="site">
              <div className="site-content">
                <TopNav />
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/signup" component={Signup} />
                  <Route exact path="/forgot" component={ForgotPassword} />
                  <Route exact path="/reset" component={ResetPassword} />
                  <>
                    <Navbar />
                    <Switch>
                      <this.PrivateRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                        // onEnter={checkLoginStatus}
                      />
                      <this.PrivateRoute
                        exact
                        path="/history"
                        component={History}
                      />
                      <this.PrivateRoute
                        exact
                        path="/keystatistics"
                        component={KeyStatistics}
                      />
                      <this.PrivateRoute
                        exact
                        path="/collections"
                        component={Collections}
                      />
                      <this.PrivateRoute
                        exact
                        path="/expenditures"
                        component={Expenditures}
                      />
                      <this.PrivateRoute
                        exact
                        path="/facilities"
                        component={Facilities}
                      />
                      <this.PrivateRoute
                        exact
                        path="/services"
                        component={Services}
                      />
                      <this.PrivateRoute
                        exact
                        path="/enrolment"
                        component={Enrolment}
                      />
                      <this.PrivateRoute
                        exact
                        path="/institution"
                        component={Institution}
                      />
                      <this.PrivateRoute
                        exact
                        path="/personnel"
                        component={Personnel}
                      />
                      <this.PrivateAdminRoute
                        exact
                        path="/reports"
                        component={Reports}
                      />
                      <this.PrivateAdminRoute
                        exact
                        path="/institutions"
                        component={Institutions}
                      />
                      <this.PrivateAdminRoute
                        exact
                        path="/whitelist"
                        component={Whitelist}
                      />
                      <this.PrivateAdminRoute
                        exact
                        path="/salary_csv"
                        component={SalaryCSV}
                      />
                      {/* <Route
                        exact
                        path="/dashboard"
                        component={Dashboard}
                        // onEnter={checkLoginStatus}
                      />
                      <Route
                        exact
                        path="/collections"
                        component={Collections}
                      />
                      <Route
                        exact
                        path="/expenditures"
                        component={Expenditures}
                      />
                      <Route exact path="/facilities" component={Facilities} />
                      <Route exact path="/services" component={Services} />
                      <Route exact path="/enrolment" component={Enrolment} />
                      <Route
                        exact
                        path="/institution"
                        component={Institution}
                      />
                      <Route exact path="/personnel" component={Personnel} /> */}
                      <Redirect to="/login" />
                    </Switch>
                  </>
                </Switch>

                <Footer />
              </div>
            </div>
          </ThemeProvider>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  successfulAuth,
  fetchForms,
})(App);
