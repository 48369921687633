import React, { useState, useEffect } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import axios from "axios";
import ABRCSnackbar from "../components/snackbar";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { EditOutlined } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 280,
    marginTop: 64,
    padding: 40,
  },
  textSpacing: {
    marginTop: 10,
    marginBottom: 10,
  },
  dialog: {
    padding: 20,
  },
  maxWidth: {
    width: 550,
    marginBottom: 10,
  },
}));

// Table theme
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

function Whitelists(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openAddWhitelist, setOpenAddWhitelist] = useState(false);
  const [createWhitelistSuccess, setCreateWhitelistSuccess] = useState(false);
  const [createWhitelistError, setCreateWhitelistError] = useState(false);
  const [createCopyWhitelistError, setCopyCreateWhitelistError] =
    useState(false);
  const [openEditWhitelist, setOpenEditWhitelist] = useState(false);
  const [editWhitelistSuccess, setEditWhitelistSuccess] = useState(false);
  const [editWhitelistError, setEditWhitelistError] = useState(false);
  const [deleteWhitelistSuccess, setDeleteWhitelistSuccess] = useState(false);
  const [deleteWhitelistError, setDeleteWhitelistError] = useState(false);
  const [id, setID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [institution, setInstitution] = useState("");
  const [whitelists, setWhitelists] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [quickAddUser, setQuickAddUser] = useState([]);

  useEffect(() => {
    console.log(process.env.REACT_APP_API + "/whitelist");
    fetchData();
    fetchDataInstitution();
  }, []);

  const fetchData = async () => {
    try {
      axios
        .get(process.env.REACT_APP_API + "/whitelist", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          console.log("whitelists", response);
          setWhitelists(
            response.data.sort((a, b) => {
              if (a.institution < b.institution) {
                return -1;
              }
              if (a.institution > b.institution) {
                return 1;
              }
              return 0;
            })
          );
          // setCompletionData(response.data);
          // UNCOMMENT THIS TO ENABLE LOADING
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataInstitution = async () => {
    try {
      axios
        .get(process.env.REACT_APP_API + "/institutions", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          console.log("institutions", response);
          console.log("ordered", "paper");

          setInstitutions(
            response.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
          );

          // setCompletionData(response.data);
          // UNCOMMENT THIS TO ENABLE LOADING
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateWhitelist = () => {
    const body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      institution: institution,
    };
    console.log("Create Whitelist", body);
    console.log("token", localStorage.token);
    try {
      axios
        .post(process.env.REACT_APP_API + "/whitelist", body, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          console.log("whitelists", response);
          setCreateWhitelistSuccess(true);

          setOpenAddWhitelist(false);

          // Reset fields

          setFirstName("");
          setLastName("");
          setEmail("");
          setInstitution("");

          // Refresh data
          fetchData();
        })
        .catch((error) => {
          console.log("error", error.message);
          if (error.message.includes("400")) {
            setCopyCreateWhitelistError(true);
          } else {
            setCreateWhitelistError(true);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateWhitelistQuick = (one, two, three, four) => {
    const body = {
      firstName: one,
      lastName: two,
      email: three,
      institution: four,
    };
    console.log("Create Whitelist", body);
    console.log("token", localStorage.token);
    try {
      axios
        .post(process.env.REACT_APP_API + "/whitelist", body, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          console.log("whitelists", response);
          setCreateWhitelistSuccess(true);

          // setOpenAddWhitelist(false);

          // Reset fields
          setQuickAddUser("");

          setFirstName("");
          setLastName("");
          setEmail("");
          setInstitution("");

          // Refresh data
          fetchData();
        })
        .catch((error) => {
          console.log("error", error.message);
          if (error.message.includes("400")) {
            setCopyCreateWhitelistError(true);
          } else {
            setCreateWhitelistError(true);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditWhitelist = () => {
    const body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      institution: institution,
    };
    console.log("Edit Whitelist", body);
    console.log("token", localStorage.token);
    try {
      axios
        .patch(`${process.env.REACT_APP_API}/whitelist/${id}`, body, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          console.log("whitelists", response);
          setEditWhitelistSuccess(true);
          setOpenEditWhitelist(false);

          // Reset fields
          setFirstName("");
          setLastName("");
          setEmail("");
          setInstitution("");

          // Refresh data
          fetchData();
        })
        .catch((error) => {
          console.log("error", error);
          setEditWhitelistError(true);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteWhitelist = () => {
    console.log("Delete Whitelist", id);
    // console.log("token", localStorage.token);
    try {
      axios
        .delete(`${process.env.REACT_APP_API}/whitelist/${id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((response) => {
          setDeleteWhitelistSuccess(true);
          setOpenEditWhitelist(false);

          // Reset fields
          setFirstName("");
          setLastName("");
          setEmail("");
          setInstitution("");

          // Refresh data
          fetchData();
        })
        .catch((error) => {
          console.log("error", error);
          setDeleteWhitelistError(true);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickEditWhitelist = (whitelist) => {
    console.log("Edit Whitelist", whitelist);
    console.log(institutions.find((obj) => obj.name === whitelist.institution));
    setID(whitelist._id);
    setFirstName(whitelist.firstName);
    setLastName(whitelist.lastName);
    setEmail(whitelist.email);
    setInstitution(whitelist.institution);
    handleClickOpenEditWhitelist();
  };

  const handleClickOpenAddWhitelist = () => {
    setOpenAddWhitelist(true);
  };

  const handleCloseAddWhitelist = () => {
    setOpenAddWhitelist(false);
  };

  const handleCreateWhitelistSuccess = () => {
    setCreateWhitelistSuccess(false);
  };

  const handleCreateWhitelistError = () => {
    setCreateWhitelistError(false);
  };

  const handleCopyCreateWhitelistError = () => {
    setCopyCreateWhitelistError(false);
  };

  const handleClickOpenEditWhitelist = () => {
    setOpenEditWhitelist(true);
  };

  const handleCloseEditWhitelist = () => {
    setOpenEditWhitelist(false);
  };

  const handleEditWhitelistSuccess = () => {
    setEditWhitelistSuccess(false);
  };

  const handleEditWhitelistError = () => {
    setEditWhitelistError(false);
  };

  const handleDeleteWhitelistSuccess = () => {
    setDeleteWhitelistSuccess(false);
  };

  const handleDeleteWhitelistError = () => {
    setDeleteWhitelistError(false);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handlelastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleInstitutionChange = (event, value) => {
    // console.log("institution", value, institutions);
    setInstitution(value.name);
  };

  const handleQuickAddChange = (event) => {
    setQuickAddUser(event.target.value);

    // populate the other fields with the values from the field, separated by commas
    const values = event.target.value.split(",");
    if (values.length === 4) {
      console.log(values);

      setFirstName(values[2]);
      setLastName(values[3]);
      setEmail(values[1]);
      setInstitution(values[0]);
      handleCreateWhitelistQuick(values[2], values[3], values[1], values[0]);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">User White List</Typography>
      <Typography variant="body1" className={classes.textSpacing}>
        Below is a list of the user that are currently allowed to access the
        platform. On this page you can add or deactivate users. Please note that
        deactivating a user will prevent that user from signing in, but will
        retain all of that user's data in the system and accessible through
        downloads and on the Reports page.
      </Typography>
      <Button
        startIcon={<AddIcon />}
        color="primary"
        variant="contained"
        onClick={handleClickOpenAddWhitelist}
        className={classes.textSpacing}
      >
        Add User
      </Button>

      {!loading ? (
        <TableContainer>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>First Name</StyledTableCell>
                <StyledTableCell>Last Name</StyledTableCell>
                <StyledTableCell align="right">Email</StyledTableCell>
                <StyledTableCell align="right">Institution</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {whitelists.map((row) => (
                <StyledTableRow key={row.firstName}>
                  <StyledTableCell component="th" scope="row">
                    {row.firstName}
                  </StyledTableCell>
                  <StyledTableCell>{row.lastName}</StyledTableCell>
                  <StyledTableCell align="right">{row.email}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.institution}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <EditOutlined
                      onClick={() => handleClickEditWhitelist(row)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            width: "50%",
            margin: "0 auto",
            marginTop: 20,
            // marginLeft: -250,
            textAlign: "center",
          }}
        >
          <Typography component="p" style={{ fontWeight: "lighter" }}>
            Loading whitelists...
          </Typography>
          <CircularProgress style={{ margin: "3rem" }} />
        </div>
      )}

      <Dialog
        open={openAddWhitelist}
        onClose={handleCloseAddWhitelist}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
        <Typography
          variant="body1"
          className={classes.textSpacing}
          style={{ marginLeft: 20, marginRight: 20 }}
        >
          Enter the user's firstName, lastName, email, and institution to add
          them to the whitelist.
        </Typography>

        <div className={classes.dialog}>
          {/* <TextField
            autoFocus
            id="name"
            label="Quick Add (Institution, email, First Name, Last Name)"
            type="text"
            variant="outlined"
            fullWidth
            className={classes.maxWidth}
            value={quickAddUser}
            onChange={handleQuickAddChange}
          /> */}
          <TextField
            id="firstName"
            label="First Name"
            type="text"
            variant="outlined"
            fullWidth
            value={firstName}
            className={classes.maxWidth}
            onChange={handleFirstNameChange}
          />
          <TextField
            id="lastName"
            label="Last Name"
            type="text"
            variant="outlined"
            fullWidth
            value={lastName}
            className={classes.maxWidth}
            onChange={handlelastNameChange}
          />
          <TextField
            id="email"
            label="Email"
            type="text"
            variant="outlined"
            fullWidth
            value={email}
            className={classes.maxWidth}
            onChange={handleEmailChange}
          />
          <FormControl fullWidth>
            <Autocomplete
              id="tags-outlined"
              options={institutions}
              getOptionLabel={(option) => option.name}
              //   defaultValue={[institution[0]]}
              filterSelectedOptions
              onChange={handleInstitutionChange}
              // label="Group(s)"
              value={
                institution
                  ? institutions.find((obj) => obj.name === institution)
                  : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="filterSelectedOptions"
                  variant="outlined"
                  placeholder="Institution"
                  className={classes.maxWidth}
                />
              )}
            />
          </FormControl>
        </div>
        <DialogActions>
          <Button onClick={handleCloseAddWhitelist} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!(firstName && lastName && email && institution)}
            onClick={handleCreateWhitelist}
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditWhitelist}
        onClose={handleCloseEditWhitelist}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
        <Typography
          variant="body1"
          className={classes.textSpacing}
          style={{ marginLeft: 20, marginRight: 20 }}
        >
          Edit the user's firstName, lastName, email, institution, or deactivate
          them.
        </Typography>
        <div className={classes.dialog}>
          <TextField
            autoFocus
            id="firstName"
            label="First Name"
            type="text"
            variant="outlined"
            fullWidth
            className={classes.maxWidth}
            value={firstName}
            onChange={handleFirstNameChange}
          />
          <TextField
            autoFocus
            id="lastName"
            label="Last Name"
            type="text"
            variant="outlined"
            fullWidth
            className={classes.maxWidth}
            value={lastName}
            onChange={handlelastNameChange}
          />
          <TextField
            autoFocus
            id="email"
            label="Email"
            type="text"
            variant="outlined"
            fullWidth
            className={classes.maxWidth}
            value={email}
            onChange={handleEmailChange}
          />
          <FormControl fullWidth>
            <Autocomplete
              freeSolo
              id="tags-outlined"
              options={institutions}
              getOptionLabel={(option) => option.name}
              //   defaultValue={[institution[0]]}
              //   filterSelectedOptions
              onChange={handleInstitutionChange}
              //   value={institution}
              value={
                institution
                  ? institutions.find((obj) => obj.name === institution)
                  : null
              }
              // label="Group(s)"
              renderInput={(params) => (
                <TextField
                  {...params}
                  //   label="filterSelectedOptions"
                  //   value={institution}

                  variant="outlined"
                  placeholder="Institution"
                  className={classes.maxWidth}
                />
              )}
            />
          </FormControl>
        </div>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button onClick={handleDeleteWhitelist} color="danger">
            Deactivate
          </Button>
          <div>
            <Button onClick={handleCloseEditWhitelist} color="primary">
              Cancel
            </Button>
            <Button
              disabled={!(firstName && lastName && email && institution)}
              onClick={handleEditWhitelist}
              color="primary"
            >
              Edit
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <ABRCSnackbar
        open={createWhitelistSuccess}
        handleClose={handleCreateWhitelistSuccess}
        severity="success"
        message="User created successfully!"
      />
      <ABRCSnackbar
        open={createWhitelistError}
        handleClose={handleCreateWhitelistError}
        severity="error"
        message="Error - Please try again!"
      />
      <ABRCSnackbar
        open={createCopyWhitelistError}
        handleClose={handleCopyCreateWhitelistError}
        severity="error"
        message="Error - Email already exists!"
      />

      <ABRCSnackbar
        open={editWhitelistSuccess}
        handleClose={handleEditWhitelistSuccess}
        severity="success"
        message="User edited successfully!"
      />
      <ABRCSnackbar
        open={editWhitelistError}
        handleClose={handleEditWhitelistError}
        severity="error"
        message="Error - Could not Edit"
      />

      <ABRCSnackbar
        open={deleteWhitelistSuccess}
        handleClose={handleDeleteWhitelistSuccess}
        severity="success"
        message="User Deactivated!"
      />
      <ABRCSnackbar
        open={deleteWhitelistError}
        handleClose={handleDeleteWhitelistError}
        severity="error"
        message="Error - Could not Deactivate"
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    language: state.language,
  };
}

export default compose(withStyles(), connect(mapStateToProps, {}))(Whitelists);
