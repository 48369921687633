import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: 0,
    right: 10,
    color: "black",
    opacity: 0.5,
  },
}));

export default function Footer() {
  const [date, setDate] = useState();

  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="caption">
        © {date} Canadian Association of Research Libraries. Built by{" "}
        <a
          target="_blank"
          style={{ color: "#000000" }}
          href="https://alexgordienko.com/?=carl-abrc"
        >
          Organica
        </a>
        .
      </Typography>
    </div>
  );
}
