import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import * as Yup from "yup";
import axios from "axios";
import {
  Form,
  Title,
  MaterialTextField,
  SelectField,
  SubmitButton,
  SaveButton,
  MaterialSwitch,
  SubTitle,
} from "../components/form_elements";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";

import {
  successfulAuth,
  successfulAuthOut,
  fetchForms,
} from "../actions/postActions";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

// import json file
import formSchemaImport from "../form_schemas/key.json";
import formSchemaImportFR from "../form_schemas/key-fr.json";

// import 2019-2020
//  LEGACY FORMS - must be done for every year until a direct method of form creation is built for Mongo
import formSchemaImport1920 from "../form_schemas/2019-2020-forms/key.json";
import formSchemaImportFR1920 from "../form_schemas/2019-2020-forms/key-fr.json";

// import 2020-2021
import formSchemaImport2021 from "../form_schemas/2020-2021-forms/key.json";
import formSchemaImportFR2021 from "../form_schemas/2020-2021-forms/key-fr.json";

// import 2021-2022
import formSchemaImport2122 from "../form_schemas/2021-2022-forms/key.json";
import formSchemaImportFR2122 from "../form_schemas/2021-2022-forms/key-fr.json";

// import 2022-2023
import formSchemaImport2223 from "../form_schemas/2022-2023-forms/key.json";
import formSchemaImportFR2223 from "../form_schemas/2022-2023-forms/key-fr.json";

// general configuration for languages
import configuration from "../form_schemas/configuration.json";
import configurationFR from "../form_schemas/configuration-fr.json";

import qs from "qs";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 280,
    marginTop: 64,
    padding: 40,
  },
  infoContainer: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 5,
    padding: 20,
    marginTop: 40,
  },
  successContainer: {
    border: "1px solid rgba(47,197,125,1)",
    backgroundColor: "rgba(47,197,125,0.1)",
    borderRadius: 5,
    padding: 20,
    marginTop: 10,
    marginBottom: 40,
  },
  warningContainer: {
    border: "1px solid rgba(243,158,26,1)",
    backgroundColor: "rgba(243,158,26,0.1)",
    borderRadius: 5,
    padding: 20,
    margin: 40,
  },
  notice: {
    display: "flex",
    alignItems: "center",
  },
  paddingLeft: {
    paddingLeft: 10,
  },
  marginLeft: {
    marginLeft: 30,
  },
  centerJustify: {
    display: "flex",
    justifyContent: "center",
  },
}));

function KeyStatistics(props) {
  // add all accessed configuration files to arrays for access in UseEffect
  //  note that orderedConfigs and validYears should be indexed with parity.
  const orderedConfigs = [
    formSchemaImport1920,
    formSchemaImport2021,
    formSchemaImport2122,
    formSchemaImport2223,
    formSchemaImport,
  ];
  const orderedConfigsFR = [
    formSchemaImportFR1920,
    formSchemaImportFR2021,
    formSchemaImportFR2122,
    formSchemaImportFR2223,
    formSchemaImportFR,
  ];
  const validYears = configuration.ValidYears;

  const classes = useStyles();
  const history = useHistory();

  const [formData, setFormData] = useState({});
  const [formDataFR, setFormDataFR] = useState({});

  const [validationSchema, setValidationSchema] = useState({});
  const [formQuestions, setFormQuestions] = useState({});
  const [formQuestionsFR, setFormQuestionsFR] = useState({});

  const [submitState, setSubmitState] = useState();
  const [submitStateSave, setSubmitStateSave] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [shrink, setShrink] = useState(false);
  const [confirmationEmail, setConfirmationEmail] = useState(
    props.user.username
  );
  const [locked, setLocked] = useState(false);
  const collections_ref = useRef(null);

  const [formDate, setFormDate] = useState();
  const [prevFormDate, setPrevFormDate] = useState();
  const [formInstitution, setFormInstitution] = useState();
  const [formInstitutionRaw, setFormInstitutionRaw] = useState();

  let [rawFormSchema, setRawFormSchema] = useState(formSchemaImport);
  let [rawFormSchemaFR, setRawFormSchemaFR] = useState(formSchemaImportFR);
  const [yearDNE, setYearDNE] = useState(false);
  const [defaultExceptionRange, setDefaultExceptionRange] = useState(15);

  useEffect(() => {
    // if formData is set back to default, initialize the forms.
    // this is done to remove state bleed when rerendering from another component.
    // console.log("FORMDATA CHANGED", formData);
    if (!Object.keys(formData).length) {
      // console.log("BEGIN FORM RENDER");
      // choose which form to import
      let formInstitution = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
      }).inst;
      if (formInstitution) {
        setFormInstitutionRaw(formInstitution);
        setFormInstitution(formInstitution);
      } else {
        setFormInstitutionRaw(null);
        setFormInstitution(null);
      }

      let queryYear = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
      }).yr;
      // run through validYears and load in the appropriate form.
      for (let i = 0; i < validYears.length; i++) {
        // console.log("QUERY YEAR: ", queryYear, validYears[i]);
        if (queryYear === validYears[i]) {
          initForm(orderedConfigs[i], orderedConfigsFR[i]);
          setRawFormSchema(orderedConfigs[i]);
          setRawFormSchemaFR(orderedConfigsFR[i]);
          loadCurrentForm(validYears[i]);
          break;
        } else if (!queryYear) {
          // always default to most recent year
          initForm(orderedConfigs.slice(-1)[0], orderedConfigsFR.slice(-1)[0]);
          setRawFormSchema(orderedConfigs.slice(-1)[0]);
          setRawFormSchemaFR(orderedConfigsFR.slice(-1)[0]);
          loadCurrentForm(validYears.slice(-1)[0]);
          break;
        } else if (!validYears.includes(queryYear)) {
          // if query contains invalid year, display error
          setYearDNE(true);
          break;
        }
      }

      // backwards compatible linking
      if (queryYear) {
        setFormDate(queryYear);
        // check previous valid year based on qs.parse and set prev year.
        //  if no prev year, set to 'false'
        if (
          configuration.ValidYears.findIndex((y) => y === queryYear) !== -1 ||
          configuration.ValidYears.findIndex((y) => y === queryYear) !== 0
        ) {
          setPrevFormDate(
            configuration.ValidYears[
              configuration.ValidYears.findIndex((y) => y === queryYear) - 1
            ]
          );
        } else {
          setPrevFormDate(false);
        }
      } else {
        // set to current year
        setFormDate(validYears.slice(-1)[0]);
        console.log(
          "PrevDate",
          configuration.ValidYears[
            configuration.ValidYears.findIndex(
              (y) => y === validYears.slice(-2)[0]
            )
          ]
        );
        if (
          configuration.ValidYears.findIndex(
            (y) => y === validYears.slice(-1)[0]
          ) !== -1 ||
          configuration.ValidYears.findIndex(
            (y) => y === validYears.slice(-1)[0]
          ) !== 0
        ) {
          setPrevFormDate(
            configuration.ValidYears[
              configuration.ValidYears.findIndex(
                (y) => y === validYears.slice(-2)[0]
              )
            ]
          );
        } else {
          setPrevFormDate(false);
        }
      }
      console.log(
        qs.parse(props.location.search, {
          ignoreQueryPrefix: true,
        }).yr
      );
      console.log(props.language);
    }
  }, [formData]);

  useEffect(() => {
    // reset form submission state on reload
    setFormSubmitted(false);
    setShrink(false);

    // all form data and prev form data should also be reset here.
    setFormData({});
    // console.log("RESET FORM DATA", formData);
  }, [
    qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).yr,
  ]);

  const loadCurrentForm = (date) => {
    // request current year's form for user.

    let prevDate =
      configuration.ValidYears.findIndex((y) => y === date) !== -1 ||
      configuration.ValidYears.findIndex((y) => y === date) !== 0
        ? configuration.ValidYears[
            configuration.ValidYears.findIndex((y) => y === date) - 1
          ]
        : false;

    console.log("PrevDate Sent", prevDate, date);
    let fetchInstitution =
      formInstitution !== null && props.user.institution === "CARL-ABRC"
        ? formInstitution
        : props.user.institution;

    axios
      .get(process.env.REACT_APP_API + "/form-key", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          institution: fetchInstitution,
          name: "KeyStatistics",
          id: date,
          previous: true,
          prevdate: prevDate,
        },
      })
      .then(function (response) {
        // populate current form with this version
        console.log("formdata", response.data.formData);
        if (response.data.submitted) {
          setLocked(true);
          setConfirmationEmail(response.data.submissionUser);
          setFormSubmitted(true);
        } else {
          setLocked(false);
          setFormSubmitted(false);
        }
        let temp = {};
        for (var key of Object.keys(response.data.formData)) {
          temp[key] = response.data.formData[key].answer;
          // hard code number here for previous answer
          //  but what if there is no current form? The backend should return the structure of the last year's form instead.
          //  woah - this is getting more complex..

          // temp[key + "_prev"] = Math.floor(Math.random() * (200 - 100) + 100);
          temp[key + "_prev"] = parseFloat(response.data.formData[key].prev);
          temp[key + "_notes"] = response.data.formData[key].answer_notes;
          temp[key + "_na"] =
            response.data.formData[key].answer === "na" ? true : false;
        }
        setShrink(true);
        setFormData(temp);
      })
      .catch(function (error) {
        //   console.log(error.data);
        console.log(error);
      });
  };

  const initForm = (formSchema, formSchemaFR) => {
    let _formData = {};
    let _formDataFR = {};
    let _validationSchema = {};
    let _formQuestions = {};
    let _formQuestionsFR = {};

    for (var key of Object.keys(formSchema)) {
      console.log("keys", formSchema);
      if (formSchema[key].type != "title" && formSchema[key].type != "meta") {
        _formData[key] = "";
        _formDataFR[key] = "";

        _formData[key + "_notes"] = "";
        _formDataFR[key + "_notes"] = "";

        _formQuestions[key] = { question: formSchema[key].label };
        _formQuestionsFR[key] = { question: formSchemaFR[key].label };

        if (formSchema[key].type === "number") {
          _formData[key + "_na"] = false;
          _formDataFR[key + "_na"] = false;
        } else if (formSchema[key].type === "email") {
          _validationSchema[key] = Yup.string().email();
        } else if (formSchema[key].type === "text") {
          _validationSchema[key] = Yup.string();
        } else if (formSchema[key].type === "select") {
          _validationSchema[key] = Yup.string().oneOf(
            formSchema[key].options.map((o) => o.value)
          );
        } else if (formSchema[key].type === "switch") {
          _validationSchema[key] = Yup.bool();
        }
        _validationSchema[key + "_notes"] = Yup.string();

        if (formSchema[key].required && formSchema[key].type !== "number") {
          if (props.language == "fr") {
            _validationSchema[key] =
              _validationSchema[key].required("Obligatoire");
          } else {
            _validationSchema[key] =
              _validationSchema[key].required("Required");
          }
        }
      }
    }

    console.log(_formData);
    setFormData(_formData);
    setFormDataFR(_formDataFR);

    console.log(_formQuestions);
    console.log(_formQuestionsFR);

    setFormQuestions(_formQuestions);
    setFormQuestionsFR(_formQuestionsFR);

    setValidationSchema(Yup.object().shape({ ..._validationSchema }));
  };

  const getFormElement = (elementName, elementSchema, language, prevData) => {
    console.log(elementName);
    let props = {
      name: elementName,
      label: elementSchema.label,
      description: elementSchema.description,
      type: elementSchema.type,
      options: elementSchema.options,
      validation: elementSchema.validation,
      longDescription: elementSchema.longDescription,
      exceptionRange: elementSchema.exceptionRange,
      initialValue:
        formData[elementName] &&
        elementSchema.type === "number" &&
        formData[elementName] === "na"
          ? 0
          : formData[elementName],
      naCheckbox:
        formData[elementName] &&
        elementSchema.type === "number" &&
        formData[elementName] === "na"
          ? true
          : false,
      initialNotes: formData[elementName + "_notes"],
      language: language,
    };
    if (elementSchema.type === "title" && elementSchema.subtitle) {
      return <SubTitle {...props} />;
    } else if (elementSchema.type === "title") {
      return <Title {...props} />;
    }
    // useEffect(() => {
    console.log("formData[elementName]", formData[elementName]);
    // }, [formData[elementName]]);

    if (elementSchema.type === "switch") {
      if (language == "en") {
        props["yes"] = configuration.General["yes"];
        props["no"] = configuration.General["no"];
      } else {
        props["yes"] = configurationFR.General["yes"];
        props["no"] = configurationFR.General["no"];
      }
      if (shrink) {
        props["shrink"] = true;
        props["disabled"] = formSubmitted;

        return <MaterialSwitch {...props} />;
      } else {
        return <MaterialSwitch {...props} />;
      }
    }

    if (
      elementSchema.type === "number" ||
      elementSchema.type === "email" ||
      elementSchema.type === "text"
    ) {
      if (shrink) {
        props["shrink"] = true;
        props["disabled"] = formSubmitted;
        // if it's a field where the previous value was a number, then populate the relevant exception values
        // exceptionTextShort, exceptionTitle, exceptionTextLong, exceptionStatus,
        if (
          prevFormDate &&
          (prevData || prevData === 0) &&
          typeof prevData === "number"
        ) {
          props["exceptionStatus"] = true;
          console.log("LANG", props.language);
          if (language == "en") {
            // specify previous year
            props["exceptionTextShort"] =
              prevFormDate.slice(0, 4) +
              "/" +
              prevFormDate.slice(-2) +
              " " +
              configuration.ExceptionValidation["Value"] +
              ": ";
            props["exceptionValue"] = prevData;
            props["exceptionTitle"] =
              configuration.ExceptionValidation["Title"];
            if (elementSchema.exceptionRange) {
              props["exceptionTextLong"] = configuration.ExceptionValidation[
                "TextLong"
              ].replace("{{Percentage}}", elementSchema.exceptionRange + "%");
              props["exceptionPercentage"] = elementSchema.exceptionRange;
            } else {
              props["exceptionTextLong"] = configuration.ExceptionValidation[
                "TextLong"
              ].replace("{{Percentage}}", defaultExceptionRange + "%");
              props["exceptionPercentage"] = defaultExceptionRange;
            }
            // exception logic
            // formData[elementName] > prevData * (1 + defaultExceptionRange/100) || formData[elementName] < prevData * (1 - defaultExceptionRange/100)
            props["exceptionActionText"] =
              configuration.ExceptionValidation["ActionText"];
            // parseFloat(formData[elementName]) >
            //   prevData * (1 + defaultExceptionRange / 100) ||
            // parseFloat(formData[elementName]) <
            //   prevData * (1 - defaultExceptionRange / 100)
            //   ? configuration.ExceptionValidation["ActionText"]
            //   : null;
            // setInterval(function () {
            //   // console.log(Math.floor(Math.random() * 100 + 1));
            //   return Math.floor(Math.random() * 100 + 1);
            // }, 1000);
            props["exceptionCompleteText"] =
              configuration.ExceptionValidation["ActionComplete"];
          } else {
            // specify previous year
            props["exceptionTextShort"] =
              prevFormDate.slice(0, 4) +
              "/" +
              prevFormDate.slice(-2) +
              " " +
              configurationFR.ExceptionValidation["Value"] +
              ": ";
            props["exceptionValue"] = prevData;
            props["exceptionTitle"] =
              configurationFR.ExceptionValidation["Title"];
            if (elementSchema.exceptionRange) {
              props["exceptionTextLong"] = configurationFR.ExceptionValidation[
                "TextLong"
              ].replace("{{Percentage}}", elementSchema.exceptionRange + "%");
              props["exceptionPercentage"] = elementSchema.exceptionRange;
            } else {
              props["exceptionTextLong"] = configurationFR.ExceptionValidation[
                "TextLong"
              ].replace("{{Percentage}}", defaultExceptionRange + "%");
              props["exceptionPercentage"] = defaultExceptionRange;
            }
            props["exceptionActionText"] =
              configurationFR.ExceptionValidation["ActionText"];
            props["exceptionCompleteText"] =
              configurationFR.ExceptionValidation["ActionComplete"];
          }

          return <MaterialTextField {...props} />;
        } else {
          return <MaterialTextField {...props} />;
        }
      } else {
        return <MaterialTextField {...props} />;
      }
    }

    if (elementSchema.type === "select") {
      return <SelectField {...props} />;
    }
  };

  // Save the current state of the form without running validation
  const saveCurrent = (values) => {
    console.log("saving", values);
    let mergedForm = {};
    for (var key of Object.keys(formData)) {
      if (formQuestions[key]) {
        if (rawFormSchema[key].type === "switch") {
          if (!values[key]) {
            mergedForm[key] = {
              question: formQuestions[key].question,
              questionFR: formQuestionsFR[key].question,
              answer: false,
            };
          } else {
            mergedForm[key] = {
              question: formQuestions[key].question,
              questionFR: formQuestionsFR[key].question,
              answer: values[key],
            };
          }
        } else {
          mergedForm[key] = {
            question: formQuestions[key].question,
            questionFR: formQuestionsFR[key].question,
            answer: values[key],
          };
        }
        if (values[key + "_notes"]) {
          mergedForm[key].answer_notes = values[key + "_notes"];
        }
        if (values[key + "_na"]) {
          mergedForm[key].answer = "na";
        }
        if (!values[key + "_na"] && !values[key]) {
          if (values[key] === 0) {
            mergedForm[key].answer = 0;
          } else {
            mergedForm[key].answer = "";
          }
        }
      }
    }

    let finalSubmission = {
      name: "KeyStatistics",
      institution: props.user.institution,
      submitted: false,
      submissionDate: new Date(),
      submissionUser: props.user.username,
      id: formDate,
      formData: mergedForm,
    };
    axios
      .post(process.env.REACT_APP_API + "/form", {
        headers: { "Access-Control-Allow-Origin": "*" },
        form: finalSubmission,
      })
      .then(function (response) {
        console.log(response);
        props.fetchForms(props.user.institution, formDate);

        if (props.language == "en") {
          setSubmitStateSave(configuration.General["saved"]);
        } else {
          setSubmitStateSave(configurationFR.General["saved"]);
        }

        setTimeout(function () {
          if (props.language == "en") {
            setSubmitStateSave(configuration.General["save"]);
          } else {
            setSubmitStateSave(configurationFR.General["save"]);
          }
        }, 5000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    console.log("values");
    console.log(values);
    setSubmitting(false);

    // merge questions and answers
    let mergedForm = {};

    for (var key of Object.keys(formData)) {
      if (formQuestions[key]) {
        if (rawFormSchema[key].type === "switch") {
          if (!values[key]) {
            mergedForm[key] = {
              question: formQuestions[key].question,
              questionFR: formQuestionsFR[key].question,
              answer: false,
            };
          } else {
            mergedForm[key] = {
              question: formQuestions[key].question,
              questionFR: formQuestionsFR[key].question,
              answer: values[key],
            };
          }
        } else {
          mergedForm[key] = {
            question: formQuestions[key].question,
            questionFR: formQuestionsFR[key].question,
            answer: values[key],
          };
        }
        if (values[key + "_notes"]) {
          mergedForm[key].answer_notes = values[key + "_notes"];
        }
        if (values[key + "_na"]) {
          mergedForm[key].answer = "na";
        }
        if (!values[key + "_na"] && !values[key]) {
          if (values[key] === 0) {
            mergedForm[key].answer = 0;
          } else {
            mergedForm[key].answer = "";
          }
        }
      }
    }

    // either do a final submission or just save the form
    console.log(submitStateSave);
    console.log("button", window.event.submitter.id);
    if (window.event.submitter.id === "save") {
      let finalSubmission = {
        name: "KeyStatistics",
        institution: props.user.institution,
        submitted: false,
        submissionDate: new Date(),
        submissionUser: props.user.username,
        id: formDate,
        formData: mergedForm,
      };

      axios
        .post(process.env.REACT_APP_API + "/form", {
          headers: { "Access-Control-Allow-Origin": "*" },
          form: finalSubmission,
        })
        .then(function (response) {
          console.log(response);
          props.fetchForms(props.user.institution, formDate);

          setSubmitStateSave("Saved!");
          setTimeout(function () {
            setSubmitStateSave("Save");
          }, 5000);
        })
        .catch(function (error) {
          //   console.log(error.data);
          console.log(error);
        });
    } else {
      // is a final submission
      let finalSubmission = {
        name: "KeyStatistics",
        institution: props.user.institution,
        submitted: true,
        submissionDate: new Date(),
        submissionUser: props.user.username,
        id: formDate,
        formData: mergedForm,
      };

      axios
        .post(process.env.REACT_APP_API + "/form", {
          headers: { "Access-Control-Allow-Origin": "*" },
          form: finalSubmission,
        })
        .then(function (response) {
          console.log(response);
          setSubmitState("Submitted!");
          setFormSubmitted(true);
          resetForm();
          loadCurrentForm(formDate);
          props.fetchForms(props.user.institution, formDate);
          window.scrollTo(0, 0);
        })
        .catch(function (error) {
          //   console.log(error.data);
          console.log(error.response.data);
        });
    }
  };

  return (
    <div className={classes.root}>
      {yearDNE ? (
        <>
          <div className={classes.warningContainer}>
            <div className={classes.notice}>
              <CheckCircleIcon />{" "}
              <Typography className={classes.paddingLeft} variant="h6">
                {props.language == "en" ? (
                  <>{configuration.YearDNE["title"]}</>
                ) : (
                  <>{configurationFR.YearDNE["title"]}</>
                )}
              </Typography>
            </div>

            <br />
            <Typography variant="body1">
              {props.language == "en" ? (
                <>{configuration.YearDNE["text"]}</>
              ) : (
                <>{configurationFR.YearDNE["text"]}</>
              )}{" "}
            </Typography>
          </div>
        </>
      ) : (
        <>
          {props.language == "en" ? (
            <>
              <Typography variant="h4" style={{ whiteSpace: "pre-line" }}>
                {rawFormSchema.FormID.name}
                {formDate ? (
                  <>
                    : {formDate}
                    {formInstitution &&
                      props.user.institution === "CARL-ABRC" &&
                      ` - ${formInstitution}`}
                  </>
                ) : (
                  <>
                    : {rawFormSchema.FormID.data}
                    {formInstitution &&
                      props.user.institution === "CARL-ABRC" &&
                      ` - ${formInstitution}`}
                  </>
                )}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h4">
                {rawFormSchemaFR.FormID.name}
                {formDate ? (
                  <>
                    : {formDate}
                    {formInstitution &&
                      props.user.institution === "CARL-ABRC" &&
                      ` - ${formInstitution}`}
                  </>
                ) : (
                  <>
                    : {rawFormSchemaFR.FormID.data}
                    {formInstitution &&
                      props.user.institution === "CARL-ABRC" &&
                      ` - ${formInstitution}`}
                  </>
                )}
              </Typography>
            </>
          )}
          <br />

          {formSubmitted && (
            <div className={classes.successContainer}>
              <div className={classes.notice}>
                <CheckCircleIcon />{" "}
                <Typography className={classes.paddingLeft} variant="h6">
                  {props.language == "en" ? (
                    <>{configuration.Success["title"]}</>
                  ) : (
                    <>{configurationFR.Success["title"]}</>
                  )}
                </Typography>
              </div>

              <br />
              <Typography variant="body1">
                {props.language == "en" ? (
                  <>
                    {configuration.Success["text"].replace(
                      "{{Survey}}",
                      rawFormSchema.FormID.name
                    )}
                  </>
                ) : (
                  <>
                    {configurationFR.Success["text"].replace(
                      "{{Survey}}",
                      rawFormSchemaFR.FormID.name
                    )}
                  </>
                )}{" "}
                <b>{confirmationEmail}</b>
              </Typography>
            </div>
          )}
          {props.language == "en" ? (
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {rawFormSchema.FormID.longDescription}
            </Typography>
          ) : (
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {rawFormSchemaFR.FormID.longDescription}
            </Typography>
          )}

          <div className={classes.infoContainer}>
            <div className={classes.notice}>
              <CheckCircleIcon />{" "}
              <Typography className={classes.paddingLeft} variant="h6">
                {props.language == "en" ? (
                  <>{configuration.Instructions["title"]}</>
                ) : (
                  <>{configurationFR.Instructions["title"]}</>
                )}
              </Typography>
            </div>

            <br />
            <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
              {props.language == "en" ? (
                <>{configuration.Instructions["text"]}</>
              ) : (
                <>{configurationFR.Instructions["text"]}</>
              )}
            </Typography>
            <br />
          </div>
          {/* Form Begins */}
          <Form
            innerRef={collections_ref}
            enableReinitialize
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={formData}
            onSubmit={onSubmit}
          >
            {props.language == "en" ? (
              <>
                {Object.keys(rawFormSchema).map((key, ind) => (
                  <div key={ind}>
                    {getFormElement(
                      key,
                      rawFormSchema[key],
                      "en",
                      formData[key + "_prev"]
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>
                {Object.keys(rawFormSchemaFR).map((key, ind) => (
                  <div key={ind}>
                    {getFormElement(
                      key,
                      rawFormSchemaFR[key],
                      "fr",
                      formData[key + "_prev"]
                    )}
                  </div>
                ))}
              </>
            )}

            <div className={classes.centerJustify}>
              <Button
                disabled={
                  formSubmitted ||
                  formInstitutionRaw
                }
                variant="outlined"
                color="primary"
                type="button"
                style={{ marginRight: 30 }}
                onClick={() => {
                  console.log("Clicked Save");
                  if (props.language == "en") {
                    setSubmitStateSave(configuration.General["saving"]);
                  } else {
                    setSubmitStateSave(configurationFR.General["saving"]);
                  }
                  saveCurrent(collections_ref.current.values);
                }}
              >
                {submitStateSave ? (
                  <>{submitStateSave}</>
                ) : (
                  <>
                    {props.language == "en" ? (
                      <>{configuration.General["save"]}</>
                    ) : (
                      <>{configurationFR.General["save"]}</>
                    )}
                  </>
                )}
              </Button>
              <SubmitButton
                disabled={
                  formSubmitted ||
                  formInstitutionRaw
                }
                // title={submitState}
                id="submit"
                title={
                  props.language == "en" ? (
                    <>{configuration.General["save-and-submit"]}</>
                  ) : (
                    <>{configurationFR.General["save-and-submit"]}</>
                  )
                }
              ></SubmitButton>
            </div>
          </Form>
        </>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    language: state.language,
  };
}

export default compose(
  withStyles(),
  connect(mapStateToProps, {
    successfulAuthOut,
    fetchForms,
  })
)(withRouter(KeyStatistics));
