import React, { useEffect } from "react";
import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  useFormikContext,
  useField,
  useFormik,
  FieldArray,
} from "formik";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { TextField, Switch, Checkbox } from "formik-material-ui";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import { Help, NoteAdd } from "@material-ui/icons";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { CSVReader } from "react-papaparse";
// import { useField } from 'formik';

export function Form(props) {
  return (
    <Formik {...props}>
      {({ errors, touched, validateField, validateForm }) => (
        <FormikForm className="needs-validation" noValidate="">
          {props.children}
        </FormikForm>
      )}
    </Formik>
  );
}

export function Title(props) {
  const { name, longDescription } = props;
  return (
    <div style={{ paddingBottom: 0 }}>
      <hr
        style={{
          border: "0.5px solid rgba(0, 0, 0, 0.12)",
          width: "100%",
          marginTop: 40,
          borderTop: "0.5px",
        }}
      />
      <Typography variant="h5" style={{ marginTop: 40, marginBottom: 10 }}>
        {name}
      </Typography>
      {longDescription && longDescription.length > 1 && (
        <>
          <Typography
            variant="body2"
            style={{
              marginTop: 20,
              paddingBottom: 20,
              color: "grey",
              whiteSpace: "pre-line",
            }}
          >
            {longDescription}
          </Typography>
          <br />
          <br />
        </>
      )}
    </div>
  );
}

export function SubTitle(props) {
  const { name } = props;
  return (
    <>
      <Typography variant="h6" style={{ marginTop: 50, marginBottom: 40 }}>
        {name}
      </Typography>
    </>
  );
}

export function MaterialTextField(props) {
  let {
    name,
    label,
    placeholder,
    type,
    description,
    longDescription,
    validation,
    shrink,
    disabled,
    calculation,
    exceptionTextShort,
    exceptionTitle,
    exceptionTextLong,
    exceptionStatus,
    exceptionValue,
    exceptionActionText,
    exceptionCompleteText,
    language,
    ...rest
  } = props;
  const [open, setOpen] = React.useState(false);
  const [openException, setOpenException] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenException = () => {
    setOpenException(!openException);
  };

  const [openNotes, setOpenNotes] = React.useState(false);
  const [showException, setShowException] = React.useState(false);
  const [showExceptionFulfilled, setShowExceptionFulfilled] =
    React.useState(false);

  const handleClickOpenNotes = () => {
    setOpenNotes(!openNotes);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const [checkbox, setCheckbox] = React.useState(props.naCheckbox);
  const [checkbox, setCheckbox] = React.useState(false);

  const [localDisabled, setLocalDisabled] = React.useState(props.naCheckbox);
  const [checkBoxDisabled, setCheckBoxDisabled] = React.useState(
    props.disabled
  );

  useEffect(() => {
    // console.log("RUN ONCE validation type", type);
    if (props.disabled) {
      setLocalDisabled(true);
      setCheckBoxDisabled(true);
    } else {
      console.log("local disabled: ", props.naCheckbox, props);
      setLocalDisabled(props.disabled || props.naCheckbox);
      setCheckBoxDisabled(props.disabled);
      setOpenException(false);
    }

    // console.log(
    //   "INITIAL CHECKBOX: ",
    //   props.naCheckbox,
    //   props.disabled,
    //   props.naCheckbox
    // );
    // console.log("INITIAL Value: ", props.initialValue);
  }, [props.naCheckbox, props.disabled]);

  const handleFieldChange = (event) => {
    console.log("changed!", event.target.value);
    // run bounds validation
    if (
      parseFloat(event.target.value) >
        exceptionValue * (1 + props.exceptionPercentage / 100) ||
      parseFloat(event.target.value) <
        exceptionValue * (1 - props.exceptionPercentage / 100)
    ) {
      setShowException(true);
    } else {
      setShowException(false);
    }
  };

  const handleNotesChange = (event) => {
    console.log("changed!", event.target.value);
    // run bounds validation
    if (event.target.value) {
      setShowExceptionFulfilled(true);
    } else {
      setShowExceptionFulfilled(false);
    }
  };

  const clickedCheckbox = (event) => {
    console.log("CLICKED CHECKBOX");
    setLocalDisabled(!localDisabled);
    setCheckbox(!checkbox);
  };

  useEffect(() => {
    // console.log("RUN ONCE validation type", type);
    if (
      parseFloat(props.initialValue) >
        exceptionValue * (1 + props.exceptionPercentage / 100) ||
      parseFloat(props.initialValue) <
        exceptionValue * (1 - props.exceptionPercentage / 100)
    ) {
      setShowException(true);
    } else {
      setShowException(false);
    }
    if (props.initialNotes) {
      setShowExceptionFulfilled(true);
    } else {
      setShowExceptionFulfilled(false);
    }
    // console.log(
    //   "INITIAL CHECKBOX: ",
    //   props.naCheckbox,
    //   props.disabled,
    //   props.naCheckbox
    // );
    // console.log("INITIAL Value: ", props.initialValue);
  }, [props.initialValue, props.initialNotes]);

  function validateNumber(value) {
    let error;
    if ((value === 0 || value === "0") && !localDisabled) {
      console.log("attempting to validate: ", typeof value, type, value === 0);
      error = "";
      return error;
    } else if (
      typeof value !== "number" &&
      !localDisabled &&
      type === "number"
    ) {
      error = "Required";
      return error;
    } else if (localDisabled) {
      error = "";
      return error;
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "100%",
          marginBottom: 20,
        }}
      >
        <div style={{ marginRight: 30, maxWidth: 500 }}>
          <Typography
            style={{ marginBottom: 10, display: "flex" }}
            variant="body1"
          >
            {label}{" "}
            {longDescription && longDescription != " " && (
              <HelpOutlineOutlinedIcon
                onClick={handleClickOpen}
                style={{ opacity: 0.6, height: "18px", marginLeft: "5px" }}
              />
            )}
            <NoteAdd
              onClick={handleClickOpenNotes}
              style={{ opacity: 0.6, height: "18px", marginLeft: "5px" }}
            />
          </Typography>
          <Typography
            variant="body2"
            style={{ wordWrap: "anywhere", color: "grey" }}
          >
            {description}
          </Typography>
        </div>
        <div style={{ zIndex: 0, minWidth: 300 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {type === "number" && (
              <div
                style={{
                  minWidth: 50,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 10,
                  marginTop: 0,
                }}
              >
                <Field
                  component={Checkbox}
                  type="checkbox"
                  onClick={clickedCheckbox}
                  name={name + "_na"}
                  id={name + "_na"}
                  key={name + "_na"}
                  color="primary"
                  // validate={validateNumber}
                  disabled={checkBoxDisabled}
                  indeterminate={false}
                />{" "}
                <Typography variant="overline" style={{ marginTop: -10 }}>
                  {checkBoxDisabled ? (
                    <div style={{ opacity: 0.5 }}>
                      {props.language == "en" ? <>U/A</> : <>N.D.</>}
                    </div>
                  ) : (
                    <>{props.language == "en" ? <>U/A</> : <>N.D.</>}</>
                  )}
                </Typography>
              </div>
            )}
            <Field
              component={TextField}
              label={validation}
              variant="outlined"
              className="form-control"
              type={type}
              name={name}
              id={name}
              key={name}
              validate={validateNumber}
              style={{ width: "100%" }}
              InputLabelProps={{ shrink: shrink }}
              disabled={localDisabled}
              onBlur={handleFieldChange}
            />
          </div>

          {exceptionStatus && (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                width: "100%",
                maxWidth: "100%",
                marginTop: 5,
              }}
            >
              {exceptionTextShort} {exceptionValue}
              {showException && !showExceptionFulfilled && (
                <>
                  {" | "}
                  <div
                    onClick={handleClickOpenException}
                    style={{
                      marginLeft: 3,
                      textDecorationLine: "underline",
                      fontWeight: "bold",
                      color: "#FC6273",
                      cursor: "pointer",
                    }}
                  >
                    {exceptionActionText} {props.answer}
                  </div>
                </>
              )}
              {showException && showExceptionFulfilled && (
                <>
                  {" | "}
                  <div
                    onClick={handleClickOpenException}
                    style={{
                      marginLeft: 3,
                      textDecorationLine: "underline",
                      fontWeight: "bold",
                      color: "#2FC57D",
                      cursor: "pointer",
                    }}
                  >
                    {exceptionCompleteText}
                  </div>
                </>
              )}
            </div>
          )}
          {openNotes && openNotes != " " && (
            <div style={{ zIndex: 0, minWidth: 300, marginTop: 20 }}>
              <Field
                component={TextField}
                multiline
                rowsMax={Infinity}
                label="Notes"
                variant="outlined"
                className="form-control"
                type="text"
                name={name + "_notes"}
                id={name + "_notes"}
                key={name + "_notes"}
                style={{ width: "100%" }}
                InputLabelProps={{ shrink: shrink }}
                // disabled={localDisabled}
                onBlur={handleNotesChange}
              />
            </div>
          )}
        </div>
      </div>
      {openException && openException != " " && (
        <div
          style={{
            backgroundColor: "#FDEDEE",
            border: "1px solid #E6475A",
            borderRadius: 5,
            padding: 20,
            marginBottom: 60,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon />{" "}
            <Typography style={{ paddingLeft: 10 }} variant="h6">
              {exceptionTitle}
            </Typography>
          </div>

          <br />
          <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
            {exceptionTextLong}
          </Typography>
          <br />

          <div
            style={{
              zIndex: 0,
              minWidth: 300,
              marginTop: 20,
              background: "white",
            }}
          >
            <Field
              component={TextField}
              background="white"
              multiline
              rowsMax={Infinity}
              rows={3}
              label="Notes"
              variant="outlined"
              className="form-control"
              type="text"
              name={name + "_notes"}
              id={name + "_notes"}
              key={name + "_notes"}
              style={{ width: "100%" }}
              InputLabelProps={{ shrink: shrink }}
              // disabled={localDisabled}
              onBlur={handleNotesChange}
            />
          </div>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Full Description"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ whiteSpace: "pre-line" }}
          >
            {longDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function SelectField(props) {
  const { name, label, options } = props;
  return (
    <>
      {label && <label for={name}>{label}</label>}
      <Field as="select" id={name} name={name}>
        <option value="">Choose...</option>
        {options.map((optn, index) => (
          <option value={optn.value} label={optn.label || optn.value} />
        ))}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
      />
    </>
  );
}

export function MaterialSwitch(props) {
  const {
    name,
    label,
    placeholder,
    type,
    description,
    longDescription,
    disabled,
    shrink,
    yes,
    no,
  } = props;
  // console.log("prop")
  // console.log(props)
  const [open, setOpen] = React.useState(false);
  // const [checked, setChecked] = React.useState(false);
  const [openNotes, setOpenNotes] = React.useState(false);
  const handleClickOpenNotes = () => {
    setOpenNotes(!openNotes);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const handleChecked = () => {
  //   setChecked(!checked);
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: 40,
        }}
      >
        <div style={{ marginRight: 30, maxWidth: 500 }}>
          <Typography style={{ marginBottom: 10 }} variant="body1">
            {label}
            {longDescription && longDescription != " " && (
              <HelpOutlineOutlinedIcon
                onClick={handleClickOpen}
                style={{ opacity: 0.6, height: "18px", marginLeft: "5px" }}
              />
            )}
            <NoteAdd
              onClick={handleClickOpenNotes}
              style={{ opacity: 0.6, height: "18px", marginLeft: "5px" }}
            />
          </Typography>
          <Typography variant="body2" style={{ color: "grey" }}>
            {description}
          </Typography>
        </div>
        <div style={{ zIndex: 0, minWidth: 300 }}>
          <div
            style={{
              // zIndex: 0,
              minWidth: 300,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" style={{ marginRight: 20 }}>
                {no}
              </Typography>
              <Field
                color="primary"
                component={Switch}
                type="checkbox"
                // checked={checked}
                // onChange={handleChecked}
                name={name}
                id={name}
                disabled={disabled}
              />
              <Typography variant="body1" style={{ marginLeft: 20 }}>
                {yes}
              </Typography>
            </div>
          </div>
          {openNotes && openNotes != " " && (
            <div style={{ zIndex: 0, minWidth: 300, marginTop: 20 }}>
              <Field
                component={TextField}
                multiline
                rowsMax={Infinity}
                label="Notes"
                variant="outlined"
                className="form-control"
                type="text"
                name={name + "_notes"}
                id={name + "_notes"}
                key={name + "_notes"}
                style={{ width: "100%" }}
                InputLabelProps={{ shrink: shrink }}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Full Description"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ whiteSpace: "pre-line" }}
          >
            {longDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function SubmitButton(props) {
  const { title, disabled, ...rest } = props;
  const { isSubmitting } = useFormikContext();

  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      disableElevation
      setFieldTouched
      id="submit"
      disabled={disabled}
    >
      {title}
    </Button>
  );
}

export function SaveButton(props) {
  const { title, disabled, ...rest } = props;
  const { isSubmitting } = useFormikContext();

  return (
    <Button
      variant="outlined"
      color="primary"
      type="button"
      disabled={disabled}
      id="save"
      style={{ marginLeft: 30 }}
    >
      {title}
    </Button>
  );
}

export function MaterialUploadField(props) {
  const {
    name,
    label,
    placeholder,
    type,
    description,
    longDescription,
    validation,
    shrink,
    disabled,
    table,
    previewTitle,
    previewText,
    setFieldValue,
    language,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(props.name);

  const [open, setOpen] = React.useState(false);
  const [head, setHead] = React.useState(false);
  const [data, setData] = React.useState(false);
  const [showData, setShowData] = React.useState(false);
  const [tableError, setTableError] = React.useState(false);

  // showStatus is a trinary where
  //  0 = show nothing - there is no data
  //  1 = show only the status div
  //  2 = show the entire csv
  const [showStatus, setShowStatus] = React.useState(0);
  const [showDataInv, setShowDataInv] = React.useState(true);

  const { value } = meta;
  const { setValue } = helpers;
  // console.log("meta", meta);
  // console.log("helpers", helpers);

  useEffect(() => {
    if (value) {
      if (value.length > 1) {
        setTableError(false);
        setHead(value[0]);
        setData(value.slice(1));

        if (showStatus === 2) {
          setShowData(true);
        } else {
          setShowStatus(1);
        }

        console.log("csv value has changed", value);
      } else {
        setData({});
        setHead({});
        setShowData(false);
        setShowStatus(0);
      }
    } else {
      setData({});
      setHead({});
      setShowData(false);
      setShowStatus(0);
    }
  }, [value]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [openNotes, setOpenNotes] = React.useState(false);
  const handleClickOpenNotes = () => {
    setOpenNotes(!openNotes);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOnDrop = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
    // this is the only case in which the data should be shown.
    // Otherwise, if there is data, only show that a file has been uploaded.
    setHead(data[0]);
    setData(data.slice(1));
    setShowDataInv(true);
    setShowData(true);
    setShowStatus(2);
    setValue(data);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const checkHeader = (value_raw, pos) => {
    let value =
      typeof value_raw === "string" && value_raw !== null
        ? value_raw.trim()
        : value_raw;
    // return a table fragment depending on if this passes or not
    switch (pos) {
      case 0:
        if (value === "Staff ID" || value === "No d'ident.") {
          return <TableCell>{value}</TableCell>;
        } else {
          // setTableError(true);
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {language === "en" && (
                <Tooltip title="Must be 'Staff ID'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {language === "fr" && (
                <Tooltip title="Doit être 'No d'ident.'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
            </TableCell>
          );
        }
      case 1:
        if (value === "Salary" || value === "Salaire") {
          return <TableCell>{value}</TableCell>;
        } else {
          // setTableError(true);
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {language === "en" && (
                <Tooltip title="Must be 'Salary'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {language === "fr" && (
                <Tooltip title="Doit être 'Salaire'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
            </TableCell>
          );
        }
      case 2:
        if (value === "Category" || value === "Catégorie du poste") {
          return <TableCell>{value}</TableCell>;
        } else {
          // setTableError(true);
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {language === "en" && (
                <Tooltip title="Must be 'Category'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {language === "fr" && (
                <Tooltip title="Doit être 'Catégorie du poste'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
            </TableCell>
          );
        }
      case 3:
        if (
          value === "Professional Experience (Years)" ||
          value === "Années d'expérience professionnelle"
        ) {
          return <TableCell>{value}</TableCell>;
        } else {
          // setTableError(true);
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {language === "en" && (
                <Tooltip title="Must be 'Professional Experience (Years)'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {language === "fr" && (
                <Tooltip title="Doit être 'Années d'expérience professionnelle'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
            </TableCell>
          );
        }
      case 4:
        if (
          value === "Professional Experience in Reporting Insitution (Years)" ||
          value === "Années d'expérience professionnelle dans l'institution"
        ) {
          return <TableCell>{value}</TableCell>;
        } else {
          // setTableError(true);
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {language === "en" && (
                <Tooltip title="Must be 'Professional Experience in Reporting Insitution (Years)'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {language === "fr" && (
                <Tooltip title="Doit être 'Années d'expérience professionnelle dans l'institution'">
                  <div>
                    {value && value.length >= 1 ? value : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
            </TableCell>
          );
        }
      default:
        return <TableCell>{value}</TableCell>;
    }
  };

  const isInteger = (value) => {
    let value_cleaned =
      typeof value === "string" && value !== null ? value.trim() : value;
    return !isNaN(value_cleaned);
  };

  const checkValue = (value_raw, expectedType) => {
    let value =
      typeof value_raw === "string" && value_raw !== null
        ? value_raw.trim()
        : value_raw;
    if (value !== undefined && value !== null) {
      if (expectedType === "number") {
        if (isInteger(value) && value >= 0 && value !== "" && value !== " ") {
          return <TableCell align="right">{value}</TableCell>;
        } else {
          if (!tableError) {
            setTableError(true);
          }
          return (
            <TableCell
              align="right"
              style={{
                backgroundColor: "#FDEDEE",
                borderRadius: 5,
              }}
            >
              {language === "en" && (
                <Tooltip title="Must be a positive number (ex. 42133.22)">
                  <div>
                    {value && value.length >= 1 && value !== " "
                      ? value
                      : "\u00A0\u00A0\u00A0"}
                  </div>
                </Tooltip>
              )}
              {language === "fr" && (
                <Tooltip title="Doit être un nombre positif (ex. 42133.22)">
                  {value && value.length >= 1 && value !== " "
                    ? value
                    : "\u00A0\u00A0\u00A0"}
                </Tooltip>
              )}
            </TableCell>
          );
        }
      } else if (typeof value === expectedType) {
        return <TableCell align="right">{value}</TableCell>;
      } else {
        if (!tableError) {
          setTableError(true);
        }
        // setTableError(true);
        return (
          <TableCell
            align="right"
            style={{
              backgroundColor: "#FDEDEE",
              borderRadius: 5,
            }}
          >
            {language === "en" && (
              <Tooltip title="Must be a string (ex. 'word')">
                {value && value.length >= 1 && value !== " "
                  ? value
                  : "\u00A0\u00A0\u00A0"}
              </Tooltip>
            )}
            {language === "fr" && (
              <Tooltip title="Doit être une chaîne (ex. 'mot')">
                {value && value.length >= 1 && value !== " "
                  ? value
                  : "\u00A0\u00A0\u00A0"}
              </Tooltip>
            )}
          </TableCell>
        );
      }
    }
  };

  const handleOnRemoveFile = (data) => {
    console.log("--------------------------- remove");
    console.log(data);
    console.log("---------------------------");
    setData({});
    setHead({});
    setShowData(false);
    setShowDataInv(false);
    setValue([]);
  };

  const removeClick = () => {
    setShowData(false);
    setShowDataInv(false);
    setValue([]);
    setData({});
    setHead({});
    console.log(showData);
  };

  return (
    <div style={{ marginBottom: 100 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "100%",
          marginBottom: 100,
        }}
      >
        <div style={{ marginRight: 30, maxWidth: 500 }}>
          <Typography
            style={{ marginBottom: 10, display: "flex" }}
            variant="body1"
          >
            {label}{" "}
            {longDescription && longDescription != " " && (
              <HelpOutlineOutlinedIcon
                onClick={handleClickOpen}
                style={{ opacity: 0.6, height: "18px", marginLeft: "5px" }}
              />
            )}
            <NoteAdd
              onClick={handleClickOpenNotes}
              style={{ opacity: 0.6, height: "18px", marginLeft: "5px" }}
            />
          </Typography>
          <Typography
            variant="body2"
            style={{ wordWrap: "anywhere", color: "grey" }}
          >
            {description}{" "}
            {language === "en" && (
              <>
                Click{" "}
                <Link to="/salary.csv" target="_blank" download>
                  here
                </Link>{" "}
                to download the CSV template.
              </>
            )}
            {language === "fr" && (
              <>
                Cliquez{" "}
                <Link to="/Salaires.csv" target="_blank" download>
                  ici
                </Link>{" "}
                pour télécharger le modèle CSV
              </>
            )}
          </Typography>
        </div>
        <div style={{ zIndex: 0, minWidth: 300 }}>
          <div>
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
              removeButtonColor="rgba(0,0,0,0.3)"
              name={name}
              id={name}
              key={name}
              style={{
                dropArea: {
                  borderRadius: 5,
                },
                height: 200,
                width: 300,
                dropFile: {
                  width: 120,
                  height: 120,
                  background: "rgba(0,0,0,0.1)",
                },
                fileSizeInfo: {
                  color: "#000",
                  backgroundColor: "transparent",
                  borderRadius: 3,
                  lineHeight: 1,
                  marginBottom: "0.5em",
                  padding: "0 0.4em",
                },
                fileNameInfo: {
                  color: "#000",
                  width: 120,
                  backgroundColor: "transparent",
                  borderRadius: 3,
                  fontSize: 14,
                  lineHeight: 1,
                  padding: "0 0.4em",
                  overflowWrap: "break-word",
                },
                removeButton: {
                  color: "white",
                },
                // progressBar: {
                //   backgroundColor: "pink",
                // },
              }}
            >
              <span>
                {language === "en" && (
                  <>Drop CSV file here or click to upload.</>
                )}
                {language === "fr" && (
                  <>Déposez le CSV ici ou cliquez pour télécharger.</>
                )}
              </span>
            </CSVReader>
          </div>

          {openNotes && openNotes != " " && (
            <div style={{ zIndex: 0, minWidth: 300, marginTop: 20 }}>
              <Field
                component={TextField}
                multiline
                rowsMax={Infinity}
                label="Notes"
                variant="outlined"
                className="form-control"
                type="text"
                name={name + "_notes"}
                id={name + "_notes"}
                key={name + "_notes"}
                style={{ width: "100%" }}
                InputLabelProps={{ shrink: shrink }}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
      {showData && showDataInv && (
        <div>
          <Typography variant="h6">{previewTitle}</Typography>
          <Typography variant="body1">{previewText}</Typography>
          <br />
          <TableContainer
            style={{
              borderStyle: "solid",
              borderColor: "rgba(0,0,0,0.3)",
              borderRadius: "10px",
              borderWidth: "1px",
              padding: 5,
            }}
          >
            <Table style={{}} aria-label="simple table">
              <TableHead>
                {head && (
                  <TableRow>
                    {/* must be "Staff ID" */}
                    {checkHeader(head.data[0], 0)}
                    {checkHeader(head.data[1], 1)}
                    {checkHeader(head.data[2], 2)}
                    {checkHeader(head.data[3], 3)}
                    {checkHeader(head.data[4], 4)}
                  </TableRow>
                )}
              </TableHead>
              {data && (
                <TableBody>
                  {data.map((row) => (
                    <TableRow key={row.name}>
                      {checkValue(row.data[0], "string")}
                      {/* <TableCell component="th" scope="row">
                        {row.data[0]}
                      </TableCell> */}
                      {checkValue(row.data[1], "number")}
                      {/* <TableCell align="right">{row.data[1]}</TableCell> */}
                      {checkValue(row.data[2], "string")}
                      {checkValue(row.data[3], "number")}
                      {checkValue(row.data[4], "number")}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* <Button style={{ marginTop: 5 }} onClick={removeClick}>
            Remove
          </Button> */}
          {tableError && (
            <>
              {language === "en" && (
                <div style={{ color: "red", marginTop: 10 }}>
                  Please change the fields in red to the correct format and
                  reupload the CSV!
                </div>
              )}
              {language === "fr" && (
                <div style={{ color: "red", marginTop: 10 }}>
                  SVP modifier les champs en rouge au format correct et
                  recharger le CSV !
                </div>
              )}
            </>
          )}
        </div>
      )}
      {showStatus === 1 && (
        <>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <div
              style={{
                border: "1px solid rgba(47,197,125,1)",
                backgroundColor: "rgba(47,197,125,0.1)",
                borderRadius: 5,
                padding: 20,
                marginTop: -60,
                marginBottom: 40,
                width: 300,
              }}
            >
              <div style={{ display: "flex", alignItems: "right" }}>
                <CheckCircleIcon />{" "}
                <Typography style={{ paddingLeft: 10 }} variant="subtitle1">
                  {language == "en" ? (
                    <>CSV File Already Uploaded</>
                  ) : (
                    <>CSV Fichier Déjà Téléchargé</>
                  )}
                </Typography>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              disabled={disabled}
              style={{ marginTop: -40 }}
              onClick={removeClick}
            >
              Remove
            </Button>
          </div>
        </>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Full Description"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ whiteSpace: "pre-line" }}
          >
            {longDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
